import { Button } from "antd";
import * as React from "react";
import styled from "styled-components";
import { EmailPart } from "../../types";

const PageBreakContainer = styled.div`
  width: calc(100% + 80px);
  background-color: #f4f3f6;
  margin: 0 -40px 0 -40px;
  padding: 0 6px 0 6px;
`;

const TopPageClosing = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05), -2px 2px 4px rgba(0, 0, 0, 0.05);
`;

const BottomPageOpening = styled.div`
  width: 100%;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #ffffff;
  box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.05),
    -2px -2px 4px rgba(0, 0, 0, 0.05);
`;

type PageBreakViewProps = {
  part: EmailPart;
  update: (part: EmailPart) => void;
};

const PageBreakView: React.FunctionComponent<PageBreakViewProps> = ({
  part,
  update,
}) => {
  return (
    <PageBreakContainer>
      <TopPageClosing>
        <Button type="primary" size="large">
          Next
        </Button>
      </TopPageClosing>
      <BottomPageOpening />
    </PageBreakContainer>
  );
};

const PageBreakRenderedView: React.FunctionComponent<PageBreakViewProps> = ({
  part,
}) => {
  return null;
};

export default PageBreakView;

export { PageBreakRenderedView };
