import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

const Container = styled.section`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type PageLoadingViewProps = {};

const PageLoadingView: React.FunctionComponent<PageLoadingViewProps> = () => {
  return (
    <Container>
      <LoadingOutlined style={{ fontSize: 55 }} spin />
    </Container>
  );
};

export default PageLoadingView;
