import { observable } from "mobx";
import { Survey } from "../types";
import { v4 as uuid } from "uuid";
import BaseStore from "./BaseStore";

export default class SurveyStore extends BaseStore {
  @observable surveys: Survey[] = [];
  collection = "surveys";

  hydrate() {
    this.whereConditions = [
      [
        "companyId",
        "in",
        this.rootStore.companyStore.companies.map((company) => company.id),
      ],
    ];
    this.fetch((surveysToHydrate: Survey[]) => {
      this.logger("hydrate:fetch:callback", surveysToHydrate.length);
      this.setSurveys(surveysToHydrate);
    });
  }

  setSurveys(surveys: Survey[]) {
    this.surveys = surveys;
  }

  newSurvey(): Promise<Survey> {
    const me = this.rootStore.appUserStore.appUser;
    const firstCompany = this.rootStore.companyStore.companies[0];
    return this.create({
      title: "Untitled Survey",
      companyId: firstCompany.id,
      flowId: "",
      content: [],
      createdBy: me?.id,
    });
  }

  updateSurvey(survey: Survey) {
    return this.update(survey);
  }

  uploadPhotoToSurveyNode(file: File): Promise<string> {
    return new Promise((resolve) => {
      const name = uuid() + "-" + file.name;
      const metadata = {
        contentType: file.type,
      };
      this.rootStore.firebase?.storage
        .ref()
        .child(name)
        .put(file, metadata)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          console.log(url);
          resolve(url);
        })
        .catch(console.error);
    });
  }
}
