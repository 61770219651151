import * as React from "react";
import { withRouter, RouteComponentProps, Prompt } from "react-router-dom";
import { History } from "history";
import { EmailPart, Survey } from "../types";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-css";
import {
  Divider,
  Button,
  message,
  Input,
  Form,
  Switch,
  Typography,
} from "antd";
import styled from "styled-components";
import CardContainer from "../components/cardContainer";
import { SurveyIcon } from "../components/icons";
import PageLoadingView from "../components/pageLoading";
import SurveyEditorView from "./surveyEditor";
import { SelectOutlined, LeftOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

type SurveysViewProps = {
  history: History;
  rootStore?: RootStore;
  surveyId?: string;
  match?: {
    params: {
      companyId: string;
      surveyId: string;
    };
  };
};

const SurveysView: React.FunctionComponent<
  SurveysViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ rootStore, history, surveyId, match: { params } }) => {
    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const [survey, setSurveyValue] = React.useState<Survey>();
    const setSurvey = (survey: Survey) => {
      setIsDirty(true);
      setSurveyValue(survey);
    };

    const foundSurveyId = surveyId || params.surveyId;
    const surveyInStore = rootStore?.surveyStore.surveys.find(
      (survey: any) => survey.id === foundSurveyId
    );
    React.useEffect(() => {
      if (surveyInStore && !survey) {
        setSurveyValue(surveyInStore);
      }
    }, [surveyInStore, survey, setSurveyValue]);

    const saveSurvey = (callback?: Function, surveyToSave?: Survey) => {
      if (!surveyToSave) surveyToSave = survey;
      if (surveyToSave) {
        rootStore?.surveyStore.updateSurvey(surveyToSave).then(() => {
          message.success("Survey Saved");
          setIsDirty(false);
          if (callback) callback();
        });
      }
    };

    const handlePreviewSurvey = () => {
      if (survey) {
        saveSurvey(() => {
          window.open(
            window.location.protocol +
              "//survey." +
              window.location.host +
              "/" +
              survey.id +
              "/" +
              rootStore?.appUserStore?.appUser?.id +
              "?preview=true"
          );
        });
      }
    };

    return (
      <CardContainer>
        <Prompt
          when={!!isDirty}
          message={(location) =>
            `Are you sure you want to leave without saving?`
          }
        />
        <UIHeader>
          <UITitle>
            <Button
              type="text"
              shape="circle"
              icon={<LeftOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => history.goBack()}
            />
            <SectionIcon>
              <SurveyIcon />
            </SectionIcon>
            <h2>Edit Survey</h2>
          </UITitle>
          <UIActions>
            <Button onClick={handlePreviewSurvey} icon={<SelectOutlined />}>
              Preview Survey
            </Button>
            <Button type="primary" onClick={() => saveSurvey()}>
              Save Survey
            </Button>
          </UIActions>
        </UIHeader>
        <Divider />
        {survey ? (
          <UISurveyEditorContainer>
            <SurveyPage>
              <UIPageCard>
                <SurveyEditorView survey={survey} setSurvey={setSurvey} />
              </UIPageCard>
            </SurveyPage>
            <SurveySidebar>
              <Form layout="vertical">
                <Form.Item label="Title">
                  <Input
                    type="text"
                    defaultValue={survey.title}
                    onChange={(e) =>
                      setSurvey({ ...survey, title: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label="Allow none users to take this survey?">
                  <Switch
                    checked={survey.isPublic}
                    onChange={(isPublic: boolean) => {
                      let currentNodes = survey.content.filter(
                        (node) => node.id !== "bb_email_address"
                      );
                      if (isPublic === true) {
                        currentNodes.push({
                          id: "bb_email_address",
                          type: "UI_TEXT",
                          value: {
                            title: "Email Address",
                            required: true,
                          },
                        } as EmailPart);
                      }

                      setSurvey({ ...survey, content: currentNodes, isPublic });
                    }}
                  />
                  {survey.isPublic ? (
                    <Paragraph
                      style={{ marginTop: 10 }}
                      ellipsis={true}
                      copyable={{
                        text:
                          window.location.protocol +
                          "//survey." +
                          window.location.host +
                          "/" +
                          survey.id,
                      }}
                    >
                      {window.location.protocol +
                        "//survey." +
                        window.location.host +
                        "/" +
                        survey.id}
                    </Paragraph>
                  ) : null}
                </Form.Item>
                <Form.Item label="Custom CSS">
                  <Editor
                    value={survey.customCSS || ""}
                    onValueChange={(code) =>
                      setSurvey({ ...survey, customCSS: code })
                    }
                    highlight={(code) => highlight(code, languages.css)}
                    padding={10}
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 12,
                      border: "#CCC 1px solid",
                      minHeight: 400,
                    }}
                  />
                </Form.Item>
              </Form>
            </SurveySidebar>
          </UISurveyEditorContainer>
        ) : (
          <PageLoadingView />
        )}
      </CardContainer>
    );
  })
);

const UISurveyEditorContainer = styled.section`
  display: flex;
`;

const SurveySidebar = styled.div`
  width: 300px;
  padding: 25px;
`;

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
`;

const SectionIcon = styled.div`
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;

const UIActions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
  }
`;

const SurveyPage = styled.div`
  display: flex;
  flex: 1;
  padding: 25px;
  justify-content: center;
  align-items: center;
  background-color: #f4f3f6;
  border-radius: 10px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
`;

const UIPageCard = styled.div`
  height: auto;
  min-height: 600px;
  width: 700px;
  padding: 40px;

  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), -2px 0px 4px rgba(0, 0, 0, 0.05),
    0px -1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

export default withRouter(SurveysView);
