import * as React from "react";
import styled from "styled-components";
import { EmailPart } from "../../types";

const EmailPartTitle = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  font-size: 16pt;
  font-weight: 800;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: transparent 2px dashed;
  color: #272343;
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

const EmailPartTitleRendered = styled.h1`
  width: 100%;
  border: none;
  font-size: 16pt;
  font-weight: 800;
  padding: 5px;
  margin-bottom: 10px;
  color: #272343;
`;

type TitleViewProps = {
  part: EmailPart;
  update: (part: EmailPart) => void;
};

const TitleView: React.FunctionComponent<TitleViewProps> = ({
  part,
  update,
}) => {
  return (
    <EmailPartTitle
      placeholder={"Title"}
      defaultValue={part.value.title}
      onBlur={(e) => update({ ...part, value: { title: e.target.value } })}
    />
  );
};

type TitleRenderedViewProps = {
  part: EmailPart;
};

const TitleRenderedView: React.FunctionComponent<TitleRenderedViewProps> = ({
  part,
}) => {
  return (
    <EmailPartTitleRendered className="title">
      {part.value.title}
    </EmailPartTitleRendered>
  );
};

export default TitleView;

export { TitleRenderedView };
