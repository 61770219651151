import { observable } from "mobx";
import { Company } from "../types";
import BaseStore from "./BaseStore";

export default class CompanyStore extends BaseStore {
  @observable companies: Company[] = [];
  collection = "companies";

  hydrate() {
    if (this.rootStore.appUserStore.appUser) {
      this.whereConditions = [
        ["members", "array-contains", this.rootStore.appUserStore.appUser.id],
      ];
      this.fetch((companiesToHydrate: Company[]) => {
        this.logger("hydrate:fetch:callback", companiesToHydrate.length);
        if (companiesToHydrate.length === 0) {
          this.rootStore.requireCompanyCreate = true;
        } else {
          this.rootStore.requireCompanyCreate = false;
          this.setCompanies(companiesToHydrate);
        }
      });
    } else {
      this.logger("hydrate", "AppUser not set");
    }
  }

  setCompanies(companies: Company[]) {
    this.companies = companies;
    this.rootStore.hydrateStoresWithUserData();
  }

  newCompany(): Promise<{ id: string }> {
    const me = this.rootStore.appUserStore.appUser;
    return this.create({
      title: "Untitled Company",
      members: [],
      createdBy: me?.id,
    });
  }

  updateCompany(company: Company) {
    return this.update(company);
  }

  /* Helpers */
  get currentCompany(): Company | undefined {
    if (this.companies && this.companies.length > 0) {
      return this.companies[0];
    }
    return undefined;
  }
}
