import * as React from "react";
import { withRouter, RouteComponentProps, Prompt } from "react-router-dom";
import { History } from "history";
import { Workflow } from "../types";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import {
  Button,
  Form,
  Input,
  Divider,
  Popconfirm,
  Switch,
  Typography,
  Modal,
  Checkbox,
  message,
} from "antd";
import styled from "styled-components";
import PageLoading from "../components/pageLoading";
import EmailEditorView from "../pages/emailEditor";
import SurveyEditorView from "../pages/editSurvey";
import WorkflowEditor from "../pages/workflowEditor";
import CardConatiner from "../components/cardContainer";
import {
  ReloadOutlined,
  QuestionCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { WorkflowIcon } from "../components/icons";

const { Paragraph } = Typography;

type EditWorkflowViewProps = RouteComponentProps & {
  history: History;
  rootStore?: RootStore;
  match: {
    params: {
      workflowId: string;
    };
  };
};

type EditWorkflowViewState = {
  flow?: Workflow;
  loading: boolean;
  isDirty: boolean;
};

@inject("rootStore")
@observer
class EditWorkflowView extends React.Component<
  EditWorkflowViewProps,
  EditWorkflowViewState
> {
  constructor(props: EditWorkflowViewProps) {
    super(props);
    this.state = {
      loading: true,
      isDirty: false,
    };
  }

  saveFlow = (callback?: Function): void => {
    console.log(this.state.flow);
    if (this.state.flow) {
      const updatedFlow: Workflow = this.state.flow;
      console.log("updatedFlow", updatedFlow);
      this.props.rootStore?.workflowStore
        .updateWorkflow(updatedFlow)
        .then(() => {
          message.success("Flow Saved");
          this.setState({ isDirty: false });
          if (callback) callback();
        });
    } else {
      if (callback) callback();
    }
  };

  runFlow = (): void => {
    const { rootStore } = this.props;
    const workflow = this.getFlow();
    const hideMessage = message.loading("Running Workflow..", 0);

    if (workflow) {
      rootStore?.firebase?.cloudFns
        .httpsCallable("runWorkflow")({
          workflowId: workflow.id,
          users: [rootStore?.appUserStore?.appUser?.id],
        })
        .then((result) => {
          console.log(result);
          if (result && result.data && result.data.error) {
            message.error("Error running workflow: " + result.data.error);
          } else {
            message.success("Workflow successfully completed");
          }
          hideMessage();
        });
    }
  };

  getFlow = (): Workflow | undefined => {
    const {
      match: {
        params: { workflowId },
      },
    } = this.props;
    if (this.state.flow) return this.state.flow;
    return this.props.rootStore?.workflowStore.workflows.find(
      (flow: any) => flow.id === workflowId
    );
  };

  handleWorkflowUpdate = (updatedFlow: Workflow): void => {
    console.log("handleWorkflowUpdate", updatedFlow);
    const existingFlow = this.state.flow ? this.state.flow : this.getFlow();
    this.setState(
      { flow: { ...existingFlow, ...updatedFlow }, isDirty: true },
      console.log
    );
  };

  render() {
    const { rootStore, history } = this.props;
    const workflow = this.getFlow();
    return (
      <CardConatiner>
        <Prompt
          when={!!this.state.isDirty}
          message={(location) =>
            `Are you sure you want to leave without saving?`
          }
        />
        <UIHeader>
          <UITitle>
            <Button
              type="text"
              shape="circle"
              icon={<LeftOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => history.goBack()}
            />
            <SectionIcon>
              <WorkflowIcon />
            </SectionIcon>

            <h2>{workflow?.title}</h2>
          </UITitle>
          <UIActions>
            <Popconfirm
              placement="bottom"
              icon={<QuestionCircleOutlined />}
              title={
                <p>
                  Are you sure you want to test this workflow
                  <br /> for user ({rootStore?.appUserStore?.appUser?.email})?
                </p>
              }
              onConfirm={() => {
                this.runFlow();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button key="test-flow-button" icon={<ReloadOutlined />}>
                Test Workflow
              </Button>
            </Popconfirm>
            <Button
              key="save-flow-button"
              type="primary"
              onClick={() => this.saveFlow()}
            >
              Save
            </Button>
          </UIActions>
        </UIHeader>
        <Divider />

        <UIWorkflowEditorContainer>
          <WorkflowPage>
            {!workflow ? (
              <PageLoading />
            ) : (
              <WorkflowEditor
                workflow={workflow}
                updateWorkflow={this.handleWorkflowUpdate}
              />
              // <FlowChart initialValue={this.state.chartState} />
            )}
          </WorkflowPage>
          <WorkflowSidebar>
            <Form layout="vertical">
              <Form.Item label="Title">
                <Input
                  type="text"
                  defaultValue={workflow?.title}
                  onChange={(e) =>
                    this.handleWorkflowUpdate({
                      title: e.target.value,
                    } as Workflow)
                  }
                />
              </Form.Item>
            </Form>
            <Form.Item label="Enable Webhook for this Workflow">
              <Switch
                checked={workflow?.webhookEnabled}
                onChange={(webhookEnabled: boolean) => {
                  this.handleWorkflowUpdate({
                    webhookEnabled,
                  } as Workflow);
                }}
              />
              {workflow?.webhookEnabled ? (
                <Paragraph
                  style={{ marginTop: 10 }}
                  ellipsis={true}
                  copyable={{
                    text: `https://${window.location.host}/workflow/webhook?api_key=${workflow.companyId}&hook_id=${workflow.id}`,
                  }}
                >
                  {`https://${window.location.host}/workflow/webhook?api_key=${workflow.companyId}&hook_id=${workflow.id}`}
                </Paragraph>
              ) : null}
            </Form.Item>
            <Form.Item label="Automatically run workflow when:">
              <Checkbox
                defaultChecked={
                  workflow?.runOn &&
                  workflow?.runOn?.indexOf("user_created") > -1
                }
                onChange={(e) => {
                  const currentRunOn = !workflow?.runOn
                    ? []
                    : workflow?.runOn.filter((rO) => rO !== "user_created");
                  if (e.target.checked) {
                    currentRunOn.push("user_created");
                  }
                  this.handleWorkflowUpdate({
                    runOn: currentRunOn,
                  } as Workflow);
                }}
              >
                User Created
              </Checkbox>
            </Form.Item>
          </WorkflowSidebar>
        </UIWorkflowEditorContainer>

        <UIFlowConatiner>
          {/* <UIFlowChart>
            {!workflow ? (
              <PageLoading />
            ) : (
              <WorkflowEditor
                workflow={workflow}
                updateWorkflow={this.handleWorkflowUpdate}
              />
              // <FlowChart initialValue={this.state.chartState} />
            )}
          </UIFlowChart> */}

          <Modal
            centered
            visible={
              Boolean(rootStore?.UIEditorEmailId) ||
              Boolean(rootStore?.UIEditorSurveyId)
            }
            footer={null}
            onCancel={() => {
              rootStore?.setUIEditorEmailId(false);
              rootStore?.setUIEditorSurveyId(false);
            }}
            width={"90%"}
            bodyStyle={{ height: "100%", minHeight: "100%" }}
            style={{ padding: 0 }}
          >
            {rootStore?.UIEditorEmailId && (
              <EmailEditorView
                onSave={() => rootStore?.setUIEditorEmailId(false)}
                emailId={
                  rootStore?.UIEditorEmailId
                    ? String(rootStore?.UIEditorEmailId)
                    : undefined
                }
              />
            )}
            {rootStore?.UIEditorSurveyId && (
              <SurveyEditorView
                surveyId={String(rootStore?.UIEditorSurveyId)}
              />
              // <SurveyEditorView
              //   onSave={() => rootStore?.setUIEditorEmailId(false)}
              //   emailId={
              //     rootStore?.UIEditorSurveyId
              //       ? String(rootStore?.UIEditorSurveyId)
              //       : undefined
              //   }
              // />
            )}
          </Modal>
        </UIFlowConatiner>
      </CardConatiner>
    );
  }
}

const UIFlowConatiner = styled.div`
  display: flex;
  flex-direction: row;
`;
const UIWorkflowEditorContainer = styled.section`
  display: flex;
`;

const WorkflowSidebar = styled.div`
  width: 300px;
  padding: 25px;
`;

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
`;

const SectionIcon = styled.div`
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;

const UIActions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
  }
`;

const WorkflowPage = styled.div`
  display: flex;
  flex: 1;
  padding: 25px;
  justify-content: center;
  align-items: center;
  background-color: #f4f3f6;
  border-radius: 10px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
`;

export default withRouter(EditWorkflowView);
