import * as React from "react";
import { CanvasEmailNode } from "./CanvasEmail";
import { CanvasSurveyNode } from "./CanvasSurvey";
import { DataUsersNode } from "./DataUsers";
import { DataUsersFilterNode } from "./DataUsersFilter";
import { BreakpointNode } from "./Breakpoint";
import { WebhookNode } from "./Webhook";
import { ReviewNode } from "./Review";
import styled from "styled-components";

export const NodeInner = (props: any) => {
  if (props.node.type === "EMAIL") {
    // pass node here so we can pull emailId from properties
    return <CanvasEmailNode {...props} />;
  } else if (props.node.type === "CANVAS_SURVEY") {
    return <CanvasSurveyNode {...props} />;
  } else if (props.node.type === "DATA_USERS") {
    return <DataUsersNode {...props} />;
  } else if (props.node.type === "USER_FILTER") {
    return <DataUsersFilterNode {...props} />;
  } else if (props.node.type === "BREAKPOINT") {
    return <BreakpointNode {...props} />;
  } else if (props.node.type === "WEBHOOK") {
    return <WebhookNode {...props} />;
  } else if (props.node.type === "SURVEY_REVIEW") {
    return <ReviewNode {...props} />;
  } else if (props.node.type === "output-only") {
    return (
      <UINodeContainer {...props}>
        <p>Use Node inner to customise the content of the node</p>
      </UINodeContainer>
    );
  } else {
    return (
      <UINodeContainer>
        <p>Add custom displays for each node.type</p>
        <p>You may need to stop event propagation so your forms work.</p>
        <br />
      </UINodeContainer>
    );
  }
};

const UINodeContainer = styled.div`
  padding: 30px;
`;
