import { observable } from "mobx";
import { Email } from "../types";
import BaseStore from "./BaseStore";

export default class EmailStore extends BaseStore {
  @observable emails: Email[] = [];
  collection = "emails";

  hydrate() {
    this.whereConditions = [
      [
        "companyId",
        "in",
        this.rootStore.companyStore.companies.map((company) => company.id),
      ],
    ];
    this.fetch((emailsToHydrate: Email[]) => {
      this.logger("hydrate:fetch:callback", emailsToHydrate.length);
      this.setEmails(emailsToHydrate);
    });
  }

  setEmails(emails: Email[]) {
    this.emails = emails;
  }

  newEmail(): Promise<Email> {
    const me = this.rootStore.appUserStore.appUser;
    const firstCompany = this.rootStore.companyStore.companies[0];
    return this.create({
      title: "Untitled Email",
      companyId: firstCompany.id,
      encodedHTML: "",
      createdBy: me?.id,
    });
  }

  updateEmail(email: Email) {
    return this.update(email);
  }
}
