import * as React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { History } from "history";
import { Survey, SurveyResult } from "../types";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import {
  Divider,
  Button,
  message,
  Form,
  Collapse,
  Checkbox,
  List,
  Avatar,
  Badge,
} from "antd";
import styled from "styled-components";
import CardContainer from "../components/cardContainer";
import { SurveyIcon } from "../components/icons";
import PageLoadingView from "../components/pageLoading";
import {
  SelectOutlined,
  AuditOutlined,
  LikeOutlined,
  DislikeOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { colorArray } from "./users";

const { Panel } = Collapse;

type ViewSurveysViewProps = {
  history: History;
  rootStore?: RootStore;
  surveyId?: string;
  match?: {
    params: {
      companyId: string;
      surveyId: string;
    };
  };
};

const ViewSurveysView: React.FunctionComponent<
  ViewSurveysViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ rootStore, history, surveyId, match: { params } }) => {
    const foundSurveyId = surveyId || params.surveyId;
    const survey: Survey | undefined = rootStore?.surveyStore.surveys.find(
      (survey: any) => survey.id === foundSurveyId
    );
    const surveyResults:
      | SurveyResult[]
      | undefined = rootStore?.surveyResultStore.surveyResults.filter(
      (surveyResult) => surveyResult.surveyId === foundSurveyId
    );

    const handlePreviewSurvey = () => {
      if (survey) {
        window.open(
          window.location.protocol +
            "//survey." +
            window.location.host +
            "/" +
            survey.id +
            "/" +
            rootStore?.appUserStore?.appUser?.id +
            "?preview=true"
        );
      }
    };

    const handleSurveyResultAction = (
      surveyResult: SurveyResult,
      action: string
    ) => {
      if (surveyResult && action) {
        const hide = message.loading(action + "ing survey", 0);
        surveyResult = {
          ...surveyResult,
          status: action,
        };

        rootStore?.surveyResultStore
          .updateSurveyResults(surveyResult)
          .then(() => {
            rootStore?.firebase?.cloudFns
              .httpsCallable("surveyReview")({
                surveyResultsId: surveyResult.id,
                action,
              })
              .then((data) => {
                console.log("Success:", data);
                message.success("Survey results successfully " + action);
                hide();
              });
          });
      }
    };

    return (
      <CardContainer>
        <UIHeader>
          <UITitle>
            <Button
              type="text"
              shape="circle"
              icon={<LeftOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => history.goBack()}
            />
            <SectionIcon>
              <SurveyIcon />
            </SectionIcon>
            <h2>View Survey</h2>
          </UITitle>
          <UIActions>
            <Button onClick={handlePreviewSurvey} icon={<SelectOutlined />}>
              Preview Survey
            </Button>
            <Button
              type="primary"
              onClick={() => history.push("/surveys/edit/" + survey?.id)}
            >
              Edit Survey
            </Button>
          </UIActions>
        </UIHeader>
        <Divider />
        {survey ? (
          <>
            <Form layout="vertical">
              <Form.Item label="Title">
                <h3>{survey.title}</h3>
              </Form.Item>
            </Form>
            <Divider />
            <h3>Results</h3>
            <Collapse defaultActiveKey={["0"]} onChange={() => {}}>
              {surveyResults?.map((result, i) => {
                let icon = <AuditOutlined />;

                if (result.status && result.status === "accept") {
                  icon = <LikeOutlined />;
                }
                if (result.status && result.status === "deny") {
                  icon = <DislikeOutlined />;
                }

                const foundUser = rootStore?.companyUsersStore.companyUsers.find(
                  (user) => {
                    console.log(user.id, result.userId);
                    return user.id === result.userId;
                  }
                );

                console.log("foundUser", foundUser);

                return (
                  <Panel
                    header={
                      <>
                        {foundUser ? (
                          <List.Item.Meta
                            avatar={
                              <Badge
                                count={icon}
                                style={{
                                  padding: "6px",
                                  borderRadius: "50%",
                                  color: "#FFF",
                                  backgroundColor:
                                    result.status === "accept"
                                      ? "#52c41a"
                                      : result.status === "deny"
                                      ? "#f50"
                                      : "#888",
                                }}
                              >
                                <Avatar
                                  size="large"
                                  style={{
                                    backgroundColor:
                                      colorArray[
                                        foundUser.email
                                          .toLowerCase()
                                          .charCodeAt(0) - 97
                                      ],
                                  }}
                                >
                                  {foundUser.email.charAt(0).toUpperCase()}
                                </Avatar>
                              </Badge>
                            }
                            title={
                              <Link to={"/users/" + foundUser.id}>
                                {foundUser.name || foundUser.email}
                              </Link>
                            }
                            description={foundUser.email}
                          />
                        ) : null}
                      </>
                    }
                    key={i}
                  >
                    <UIPageCard>
                      {Object.keys(result.results).map(
                        (questionResultKey: any) => {
                          const questionResult =
                            result.results[questionResultKey];
                          return (
                            <div key={`survey_question_${questionResultKey}`}>
                              <h3>{questionResult && questionResult.key}</h3>
                              {questionResult ? (
                                <p>
                                  {Array.isArray(questionResult.value)
                                    ? questionResult.value.map(
                                        (value: string) => (
                                          <div>
                                            <Checkbox defaultChecked disabled />{" "}
                                            {value}
                                          </div>
                                        )
                                      )
                                    : questionResult.value}
                                </p>
                              ) : null}
                            </div>
                          );
                        }
                      )}
                    </UIPageCard>
                    {!result.status && (
                      <div style={{ marginTop: 10 }}>
                        <Button
                          icon={<LikeOutlined />}
                          style={{ marginRight: 10 }}
                          onClick={() =>
                            handleSurveyResultAction(result, "accept")
                          }
                        >
                          Accept
                        </Button>
                        <Button
                          icon={<DislikeOutlined />}
                          onClick={() =>
                            handleSurveyResultAction(result, "deny")
                          }
                        >
                          Deny
                        </Button>
                      </div>
                    )}
                  </Panel>
                );
              })}
            </Collapse>
          </>
        ) : (
          <PageLoadingView />
        )}
      </CardContainer>
    );
  })
);

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
`;

const SectionIcon = styled.div`
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;

const UIActions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
  }
`;

const UIPageCard = styled.div`
  height: auto;
  padding: 40px;

  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), -2px 0px 4px rgba(0, 0, 0, 0.05),
    0px -1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-right: 40px;
`;

export default withRouter(ViewSurveysView);
