import React, { FunctionComponent } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import RootStore from "./stores/rootStore";
import { Modal, Result, Button, Form, Input, message } from "antd";
import { SmileOutlined, RocketOutlined, TeamOutlined } from "@ant-design/icons";
import moment from "moment";

import LoginView from "./pages/login";
import SignUpView from "./pages/signup";
import DashboardView from "./pages/dashboard";
import WorkflowsView from "./pages/workflows";
import UsersView from "./pages/users";
import UserFacingSurveyView from "./pages/survey";
import SurveysView from "./pages/surveys";
import EmailEditorView from "./pages/emailEditor";
import AdminView from "./pages/admin";
import PageLoading from "./components/pageLoading";
import Sidebar from "./components/sidebar";
import EditSurveyView from "./pages/editSurvey";
import ViewSurveyView from "./pages/viewSurvey";
import EditWorkflow from "./pages/editWorkflow";
import ViewWorkflow from "./pages/viewWorkflow";
import RunWorkflow from "./pages/runWorkflow";
import SettingsView from "./pages/settings";
import AccountView from "./pages/account";

const PageContainer = styled.section`
  display: flex;
  height: 100%;
`;
const Content = styled.section`
  flex: 1;
  padding-top: 50px;
  padding-right: 50px;
`;

const Footer = styled.section`
  text-align: center;
`;

type AppViewProps = {
  rootStore?: RootStore;
};

const AppView: React.FunctionComponent<AppViewProps> = inject("rootStore")(
  observer(({ rootStore }) => {
    const [companyName, setCompanyName] = React.useState<string>("");
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const isLoggedIn = Boolean(rootStore?.appUserStore.isLoggedIn);

    if (!rootStore || (rootStore && rootStore.initializing)) {
      return <PageLoading />;
    }

    // Render Survey View
    if ((window as any).location.hostname.indexOf("survey.") === 0) {
      return (
        <Router>
          <Switch>
            <Route path="/:surveyId/:userId" component={UserFacingSurveyView} />
            <Route path="/:surveyId" component={UserFacingSurveyView} />
          </Switch>
        </Router>
      );
    }

    const me = rootStore?.appUserStore.appUser;

    const RequireAuth: FunctionComponent<{}> = ({ children }) => {
      if (!isLoggedIn) {
        return <Redirect to={"/login"} />;
      }

      return <>{children}</>;
    };

    return (
      <Router>
        <PageContainer>
          <Sidebar isLoggedIn={isLoggedIn} isAdmin={me && me.isAdmin} />
          <Content>
            <Switch>
              <Route path="/login" component={LoginView} />
              <Route path="/signup" component={SignUpView} />
              <RequireAuth>
                <Route path="/dashboard" component={DashboardView} />
                <Route
                  path="/workflows"
                  exact={true}
                  component={WorkflowsView}
                />
                <Route
                  path="/workflows/edit/:workflowId"
                  exact={true}
                  component={EditWorkflow}
                />
                <Route
                  path="/workflows/run/:workflowId"
                  exact={true}
                  component={RunWorkflow}
                />
                <Route
                  path="/workflows/:workflowId"
                  exact={true}
                  component={ViewWorkflow}
                />
                <Route path="/users" component={UsersView} />
                <Route path="/surveys" exact={true} component={SurveysView} />
                <Route path="/settings" component={SettingsView} />
                <Route path="/account" component={AccountView} />
                <Route
                  path="/surveys/edit/:surveyId"
                  exact={true}
                  component={EditSurveyView}
                />
                <Route
                  path="/surveys/:surveyId"
                  exact={true}
                  component={ViewSurveyView}
                />
                <Route
                  path="/company/:companyId/email/:emailId"
                  component={EmailEditorView}
                />
                <Route
                  path="/admin"
                  component={() => {
                    if (isLoggedIn && me && me.isAdmin) {
                      return <AdminView />;
                    } else {
                      return <Redirect to={"/dashboard"} />;
                    }
                  }}
                />
              </RequireAuth>
              <Route
                path="/"
                component={() => {
                  return <Redirect to={isLoggedIn ? "/dashboard" : "/login"} />;
                }}
              />
            </Switch>
          </Content>
        </PageContainer>
        <Footer style={{ textAlign: "center" }}>©2020 RemoteLabs</Footer>
        {rootStore.appUserStore.isLoggedIn &&
        !rootStore.appUserStore.appUser?.enabled &&
        rootStore?.companyStore.currentCompany &&
        rootStore?.companyStore.currentCompany.subscription_expiration_date &&
        moment
          .unix(
            parseInt(
              rootStore?.companyStore.currentCompany
                .subscription_expiration_date
            )
          )
          .isAfter(moment()) ? (
          <Modal visible={true} closable={false} footer={null}>
            <Result
              icon={<SmileOutlined />}
              title="Your account is almost ready!"
              subTitle="Wait for your onboarding call to start using your account."
              extra={
                <Button
                  onClick={() => rootStore.appUserStore.signOut()}
                  type="link"
                >
                  Logout
                </Button>
              }
            />
          </Modal>
        ) : null}
        {rootStore.appUserStore.isLoggedIn &&
        rootStore?.companyStore.currentCompany &&
        rootStore?.companyStore.currentCompany.subscription_expiration_date &&
        moment
          .unix(
            parseInt(
              rootStore?.companyStore.currentCompany
                .subscription_expiration_date
            )
          )
          .isBefore(moment()) ? (
          <Modal visible={true} closable={false} footer={null}>
            <Result
              status="warning"
              title="Your Subscription has Expired!"
              subTitle="You will need to enable a subscription on your account to continue to use Build Better."
              extra={
                <>
                  <Button
                    type="primary"
                    loading={buttonLoading}
                    onClick={() => {
                      setButtonLoading(true);
                      rootStore.appUserStore.goToStripePortal();
                    }}
                  >
                    Fix Subscription
                  </Button>
                  <br />
                  <br />
                  <Button
                    onClick={() => rootStore.appUserStore.signOut()}
                    type="link"
                  >
                    Logout
                  </Button>
                </>
              }
            />
          </Modal>
        ) : null}
        {rootStore.appUserStore.isLoggedIn &&
        rootStore?.companyStore.currentCompany &&
        !rootStore?.companyStore.currentCompany.subscription_expiration_date ? (
          <Modal visible={true} closable={false} footer={null}>
            <Result
              icon={<RocketOutlined />}
              title="Welcome to Build Better!"
              subTitle="Let's get a subscription setup on your account."
              extra={
                <>
                  <Button
                    type="primary"
                    loading={buttonLoading}
                    onClick={() => {
                      setButtonLoading(true);
                      rootStore.appUserStore.goToStripeCheckout();
                    }}
                  >
                    Subscribe
                  </Button>
                  <br />
                  <br />
                  <Button
                    onClick={() => rootStore.appUserStore.signOut()}
                    type="link"
                  >
                    Logout
                  </Button>
                </>
              }
            />
          </Modal>
        ) : null}
        {rootStore.appUserStore.isLoggedIn && rootStore.requireCompanyCreate ? (
          <Modal visible={true} closable={false} footer={null}>
            <Result
              icon={<TeamOutlined />}
              title="Let's create a company"
              subTitle="Let's get a subscription setup on your account."
            />
            <Form
              layout="vertical"
              style={{ maxWidth: 300, margin: "0 auto", paddingBottom: 50 }}
            >
              <Form.Item label="Company Name">
                <Input
                  value={companyName}
                  type="text"
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </Form.Item>
              <Button
                type="primary"
                loading={buttonLoading}
                onClick={() => {
                  if (!companyName.trim()) {
                    return message.error("Company Name Empty");
                  }

                  const newCompanyRef = rootStore?.firebase?.db
                    .collection("companies")
                    .doc();
                  if (newCompanyRef) {
                    newCompanyRef
                      .set({
                        id: newCompanyRef.id,
                        name: companyName,
                        members: [rootStore.appUserStore.appUser?.id],
                        billing_email: rootStore.appUserStore.appUser?.email,
                      })
                      .then((newCompany) => {
                        message.success("Company Created");
                      });
                  }
                }}
              >
                Create Company
              </Button>
              <br />
              <br />
              <Button
                onClick={() => rootStore.appUserStore.signOut()}
                type="link"
              >
                Logout
              </Button>
            </Form>
          </Modal>
        ) : null}
      </Router>
    );
  })
);

export default AppView;
