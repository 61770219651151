import * as React from "react";
import styled from "styled-components";
import { inject } from "mobx-react";
import { Form, Select } from "antd";
import { AuditOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import RootStore from "../../../stores/rootStore";
import { Workflow } from "../../../types";
const { Option } = Select;

const AfterReviewTypes = ["continue-flow", "start-new-flow", "end-flow"];

export const ReviewSidebarItem = () => {
  return (
    <UISideBarConatiner>
      <AuditOutlined style={{ marginRight: 10, fontSize: 24, marginTop: 2 }} />
      <UISideBarInfoConatiner>
        <h3>Survey Review</h3>
        <p style={{ color: "#888" }}>Review before continuing the flow.</p>
      </UISideBarInfoConatiner>
    </UISideBarConatiner>
  );
};

export const ReviewNode = inject("rootStore")(
  ({
    rootStore,
    node,
    attachPropertyToNode,
  }: {
    rootStore: RootStore;
    node: {
      id: number;
      properties: {
        accept: string;
        deny: string;
        acceptNewFlowID: string;
        denyNewFlowID: string;
      };
    };
    attachPropertyToNode: (id: number, properties: {}) => void;
  }) => {
    return (
      <UIContainer>
        <UIFlowNodeContent>
          <Form layout="vertical">
            <Form.Item label="On Accept">
              <Select
                defaultValue={node.properties.accept}
                style={{ width: "200px" }}
                onChange={(value) => {
                  attachPropertyToNode(node.id, {
                    ...node.properties,
                    accept: value,
                  });
                }}
              >
                {AfterReviewTypes.map((action: string) => {
                  console.log(capitalize(action.replace(/-/gi, " ")));
                  return (
                    <Option value={action} key={`action_type_${action}`}>
                      {capitalize(action.replace(/-/gi, " "))}
                    </Option>
                  );
                })}
              </Select>
              {node.properties.accept &&
              node.properties.accept === "start-new-flow" ? (
                <Select
                  value={node.properties.acceptNewFlowID}
                  style={{ width: "200px", marginLeft: 10 }}
                  onChange={(value: string) => {
                    attachPropertyToNode(node.id, {
                      ...node.properties,
                      acceptNewFlowID: value,
                    });
                  }}
                >
                  {rootStore.workflowStore.workflows.map(
                    (workflow: Workflow) => {
                      return (
                        <Option
                          value={workflow.id}
                          key={`accept_workflow_select_${workflow.id}`}
                        >
                          {workflow.title}
                        </Option>
                      );
                    }
                  )}
                </Select>
              ) : null}
            </Form.Item>
            <Form.Item label="On Deny">
              <Select
                defaultValue={node.properties.deny}
                style={{ width: "200px" }}
                onChange={(value) => {
                  attachPropertyToNode(node.id, {
                    ...node.properties,
                    deny: value,
                  });
                }}
              >
                {AfterReviewTypes.map((action: string) => {
                  console.log(capitalize(action.replace(/-/gi, " ")));
                  return (
                    <Option value={action} key={`operator_type_${action}`}>
                      {capitalize(action.replace(/-/gi, " "))}
                    </Option>
                  );
                })}
              </Select>
              {node.properties.deny &&
              node.properties.deny === "start-new-flow" ? (
                <Select
                  value={node.properties.denyNewFlowID}
                  style={{ width: "200px", marginLeft: 10 }}
                  onChange={(value: string) => {
                    attachPropertyToNode(node.id, {
                      ...node.properties,
                      denyNewFlowID: value,
                    });
                  }}
                >
                  {rootStore.workflowStore.workflows.map(
                    (workflow: Workflow) => {
                      return (
                        <Option
                          value={workflow.id}
                          key={`deny_workflow_select_${workflow.id}`}
                        >
                          {workflow.title}
                        </Option>
                      );
                    }
                  )}
                </Select>
              ) : null}
            </Form.Item>
          </Form>
        </UIFlowNodeContent>
      </UIContainer>
    );
  }
);

const UIContainer = styled.div`
  min-width: 400px;
`;

const UISideBarConatiner = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  border: #eee 1px solid;
  border-radius: 5px;
  width: 200px;
  align-items: flex-start;
`;

const UISideBarInfoConatiner = styled.div`
  width: 250px;
`;

const UIFlowNodeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;
