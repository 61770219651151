import * as React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { ApiOutlined } from "@ant-design/icons";

const CLOUD_FUNCTION_URL =
  "https://us-central1-build-better-app.cloudfunctions.net";

export const WebhookSidebarItem = () => {
  return (
    <UISideBarConatiner>
      <ApiOutlined style={{ marginRight: 10, fontSize: 24, marginTop: 2 }} />
      <UISideBarInfoConatiner>
        <h3>Webhook</h3>
        <p style={{ color: "#888" }}>Incoming requests trigger flow.</p>
      </UISideBarInfoConatiner>
    </UISideBarConatiner>
  );
};

export const WebhookNode = ({
  node,
}: {
  node: {
    id: number;
    properties: {
      api_key: string;
      hook_id: string;
    };
  };
}) => {
  return (
    <UIContainer>
      <UIFlowNodeTitleBar>
        <ApiOutlined style={{ fontSize: 24, color: "#888", marginRight: 10 }} />
        <h3>Webhook</h3>
      </UIFlowNodeTitleBar>
      <UIFlowNodeContent>
        <Input.TextArea
          rows={2}
          value={`${CLOUD_FUNCTION_URL}/flowWebhook?api_key=${node.properties.api_key}&hook_id=${node.properties.hook_id}`}
        />
      </UIFlowNodeContent>
      <UIFlowNodeFooterBar></UIFlowNodeFooterBar>
    </UIContainer>
  );
};

const UIContainer = styled.div`
  min-width: 400px;
`;

const UISideBarConatiner = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  border: #eee 1px solid;
  border-radius: 5px;
  width: 200px;
  align-items: flex-start;
`;

const UISideBarInfoConatiner = styled.div`
  width: 250px;
`;

const UIFlowNodeTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: #eee 1px solid;
  padding: 10px;
  h3 {
    margin: 0;
  }
`;

const UIFlowNodeContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const UIFlowNodeFooterBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: #eee 1px solid;
  padding: 10px;
`;
