import { observable } from "mobx";
import { SurveyResult } from "../types";
import BaseStore from "./BaseStore";

export default class SurveyResultStore extends BaseStore {
  @observable surveyResults: SurveyResult[] = [];
  collection = "surveyResults";

  hydrate() {
    this.whereConditions = [
      [
        "companyId",
        "in",
        this.rootStore.companyStore.companies.map((company) => company.id),
      ],
    ];
    this.fetch((surveysResultsToHydrate: SurveyResult[]) => {
      this.logger("hydrate:fetch:callback", surveysResultsToHydrate.length);
      this.setSurveyResults(surveysResultsToHydrate);
    });
  }

  setSurveyResults(surveyResults: SurveyResult[]) {
    this.surveyResults = surveyResults;
  }

  updateSurveyResults(surveyResult: SurveyResult) {
    return this.update(surveyResult);
  }
}
