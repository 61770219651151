import * as React from "react";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { Workflow } from "../types";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import { Divider, Empty, Button, Tooltip } from "antd";
import { CheckCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { WorkflowIcon, CreateIcon } from "../components/icons";
import PageLoadingView from "../components/pageLoading";

type WorkflowViewProps = {
  history: History;
  rootStore?: RootStore;
};

const WorkflowView: React.FunctionComponent<
  WorkflowViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ rootStore, history }) => {
    const companies = rootStore!.companyStore.companies;
    const handleCreateWorkflow = () => {
      rootStore?.workflowStore.newWorkflow();
    };
    if (companies.length === 0) return <PageLoadingView />;

    return (
      <>
        <UIHeader>
          <UITitle>
            <WorkflowIcon /> <h2>Journeys</h2>
          </UITitle>
          <UIActions>
            <UICreateAction onClick={handleCreateWorkflow}>
              <CreateIcon />
            </UICreateAction>
          </UIActions>
        </UIHeader>
        <Divider />

        {rootStore!.workflowStore.workflows.map((flow: Workflow) => {
          return (
            <Link
              key={`${companies[0].id}_flow_${flow.id}`}
              to={"/workflows/" + flow.id}
            >
              <JourneyRow>
                <JourneyTitle>{flow.title}</JourneyTitle>
                <JourneyStatus>
                  {flow.enabled ? (
                    <Tooltip title="Currently Enabled">
                      <CheckCircleOutlined style={{ color: "#52c41a" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Currently Disabled">
                      <PauseCircleOutlined style={{ color: "#888" }} />
                    </Tooltip>
                  )}
                </JourneyStatus>
              </JourneyRow>
            </Link>
          );
        })}
        {rootStore?.workflowStore.workflows.length === 0 ? (
          <Empty
            image={<WorkflowIcon />}
            description={<span>No User Journeys</span>}
          >
            <Button onClick={handleCreateWorkflow} type="primary">
              Create Journey
            </Button>
          </Empty>
        ) : null}
      </>
    );
  })
);

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;
const UIActions = styled.div`
  display: flex;
  align-items: center;
`;
const UICreateAction = styled.div`
  cursor: pointer;
  transition: transform 250ms ease-in-out;
  transform-origin: center center;
  rect {
    fill: #bae8e8;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const JourneyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;

  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 10px;
`;

const JourneyTitle = styled.h3`
  margin: 0;
`;

const JourneyStatus = styled.div`
  font-size: 18pt;
`;

export default withRouter(WorkflowView);
