import app from "firebase/app";
import "firebase/firestore";
import { toJS } from "mobx";
import RootStore from "./rootStore";
import Logger from "../logger";

export default class BaseStore {
  rootStore: RootStore;
  snapshotUnsubscribeHandlers: Array<() => void>;
  collection: string | null;
  whereConditions: Array<
    [string, firebase.firestore.WhereFilterOp, string | string[]]
  > | null;
  logger: (
    textOrMethod: string | number | {},
    text?: string | number | {}
  ) => void;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.snapshotUnsubscribeHandlers = [];
    this.collection = null;
    this.whereConditions = [];

    this.logger = Logger(this.constructor.name);
  }

  dump() {
    this.logger("dump", " ");

    return toJS(this);
  }

  destroy() {
    this.logger("destroy", " ");

    this.snapshotUnsubscribeHandlers.forEach((handler) => handler());
  }

  fetch(onSnapshotCallback: (items: any[] | any) => void): void {
    this.logger(
      "fetch",
      this.collection + ":" + JSON.stringify(this.whereConditions)
    );

    if (!this.collection) {
      this.logger("fetch", "missing collection");
      return;
    }

    let fetchDataCollectionRef = app.firestore().collection(this.collection);

    let query:
      | app.firestore.Query<app.firestore.DocumentData>
      | undefined = undefined;
    if (this.whereConditions) {
      this.whereConditions.forEach(
        (
          condition: [
            string,
            firebase.firestore.WhereFilterOp,
            string | string[]
          ]
        ) => {
          query = (query ? query : fetchDataCollectionRef).where(...condition);
        }
      );
    }

    const fetchDataCallback = (query
      ? query
      : fetchDataCollectionRef
    ).onSnapshot((snapshots) => {
      let items: any[] = [];
      snapshots.forEach((snapshot) =>
        items.push({
          id: snapshot.id,
          ...snapshot.data(),
        })
      );

      this.logger("fetch", "onSnapshot:resolve");
      onSnapshotCallback(items);
    });

    this.snapshotUnsubscribeHandlers.push(fetchDataCallback);
  }

  create(itemToCreate: {}): Promise<{ id: string } | any> {
    return new Promise((resolve, reject) => {
      if (this.missingCollectionName()) return reject();

      const fetchDataCollectionRef = app
        .firestore()
        .collection(this.collection!);
      const newItemRef = fetchDataCollectionRef.doc();
      const newItemData = {
        ...itemToCreate,
        id: newItemRef.id,
        createdAt: app.firestore.Timestamp.now(),
        updatedAt: app.firestore.Timestamp.now(),
      };
      newItemRef.set(newItemData);

      // Analytics
      this.rootStore.analytics?.logEvent("create_" + this.collection, {});

      resolve(newItemData);
    });
  }

  update(itemToUpdate: { id: string }): Promise<{ id: string }> {
    return new Promise((resolve, reject) => {
      if (this.missingCollectionName()) return reject();
      if (!itemToUpdate.id) return reject("Item missing ID");

      const fetchDataCollectionRef = app
        .firestore()
        .collection(this.collection!);
      const updateItemRef = fetchDataCollectionRef.doc(itemToUpdate.id);
      const updateItemData = {
        ...itemToUpdate,
        updatedAt: app.firestore.Timestamp.now(),
      };
      updateItemRef.update(updateItemData);
      resolve(updateItemData);
    });
  }

  // Helpers
  missingCollectionName() {
    if (!this.collection) {
      this.logger("fetch", "missing collection");

      return true;
    } else {
      return false;
    }
  }

  widthBaseModel(model: {}) {
    return {
      id: "",
      createdAt: "",
      updatedAt: "",
      ...model,
    };
  }
}
