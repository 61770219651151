import * as React from "react";
import {
  Row,
  Col,
  List,
  Avatar,
  Button,
  Card,
  Divider,
  Empty,
  Descriptions,
  Select,
  Form,
  Tabs,
  message,
  Collapse,
  Checkbox,
  Badge,
} from "antd";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import { TeamOutlined } from "@ant-design/icons";
import { CompanyUser, Workflow } from "../types";
import CardContainer from "../components/cardContainer";
import styled from "styled-components";
import { ProfileIcon } from "../components/icons";
import RichTextEditor, { EditorValue } from "react-rte";
import {
  AuditOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

type UsersViewProps = {
  rootStore?: RootStore;
  companyId: string;
};

const { Option } = Select;
const { Panel } = Collapse;
const { TabPane } = Tabs;

export const colorArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

const UsersView: React.FunctionComponent<UsersViewProps> = inject("rootStore")(
  observer(({ rootStore }) => {
    // const [activeTab, setActiveTab] = React.useState(0);
    const [selectedUser, setSelectedUser] = React.useState<{
      id: string;
      email: string;
      name: string;
      tags: string[];
      notes?: string;
    } | null>(null);
    const fileInput = React.createRef<HTMLInputElement>();
    const [notesEditorValue, setNotesEditorValue] = React.useState<EditorValue>(
      RichTextEditor.createEmptyValue()
    );

    const getBase64 = (file: File) => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(reader.result);
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject();
        };
      });
    };

    const testFunction = () => {
      if (fileInput && fileInput.current) {
        const files = fileInput.current.files;
        if (files && files.length > 0) {
          const file = files[0];
          const hideMessage = message.loading("Uploading users..", 0);
          getBase64(file)
            .then((csv) => {
              console.log("ready for upload!");
              rootStore?.firebase?.cloudFns
                .httpsCallable("uploadUsersToCompany")({
                  companyId: rootStore?.companyStore.companies[0].id,
                  csv,
                })
                .then(
                  (result: {
                    data: {
                      success: boolean;
                      importedUsers: string[];
                      updatedUsers: string[];
                    };
                  }) => {
                    console.log(result);
                    if (result.data.success) {
                      console.log(result.data.importedUsers);
                      console.log(result.data.updatedUsers);
                      if (
                        result.data.importedUsers &&
                        result.data.importedUsers.length > 0
                      ) {
                        runFlowOnImportedUsers(result.data.importedUsers);
                      }
                    }
                    hideMessage();
                    message.success("Participant Import Successful");
                  }
                );
            })
            .catch(() => {
              hideMessage();
              console.log("error getting base64 of file");
            });
        }
      }
    };

    let additonalUserInfo: string[] = [];
    if (selectedUser) {
      additonalUserInfo = Object.keys(selectedUser)
        .filter((property) => {
          return (
            [
              "id",
              "name",
              "email",
              "companyId",
              "tags",
              "notes",
              "updatedAt",
              "createdAt",
            ].indexOf(property) === -1
          );
        })
        .sort();
    }

    const saveUser = () => {
      rootStore?.companyUsersStore
        .updateCompanyUser(selectedUser as CompanyUser)
        .then(() => message.success("User Saved"));
    };
    const saveNotes = () => {
      rootStore?.companyUsersStore
        .updateCompanyUser({
          ...selectedUser,
          notes: notesEditorValue.toString("html"),
        } as CompanyUser)
        .then(() => message.success("Notes Saved"));
    };

    const runFlowOnImportedUsers = (users: string[]) => {
      const workflowsToRun = rootStore?.workflowStore.workflows.filter(
        (workflow: any) => {
          return workflow.runOn && workflow.runOn.indexOf("user_created") > -1;
        }
      );
      if (workflowsToRun && workflowsToRun.length > 0) {
        const hideMessage = message.loading(
          "Running Workflows on Imported Users..",
          0
        );

        Promise.all(
          workflowsToRun.map((workflow: Workflow) => {
            return new Promise((resolve) => {
              if (workflow) {
                rootStore?.firebase?.cloudFns
                  .httpsCallable("runWorkflow")({
                    workflowId: workflow.id,
                    users,
                  })
                  .then((result) => {
                    console.log(result);
                    if (result && result.data && result.data.error) {
                      message.error(
                        "Error running workflow (" +
                          workflow.title +
                          "): " +
                          result.data.error
                      );
                    } else {
                      message.success(
                        "Workflow (" +
                          workflow.title +
                          ") successfully completed"
                      );
                    }
                    resolve();
                  });
              }
            });
          })
        ).then(() => {
          hideMessage();
        });
      }
    };

    return (
      <CardContainer>
        <UIHeader>
          <UITitle>
            <ProfileIcon /> <h2>Users</h2>
          </UITitle>
          <UIActions>
            {/* <UICreateAction onClick={handleCreateWorkflow}>
              <CreateIcon />
            </UICreateAction> */}
          </UIActions>
        </UIHeader>
        <Divider />

        <Col span={24}>
          <Row>
            <Col
              span={6}
              style={{ borderRight: "#EEE 1px solid", paddingRight: 15 }}
            >
              <List
                itemLayout="horizontal"
                dataSource={rootStore?.companyUsersStore.companyUsers}
                renderItem={(item) => (
                  <List.Item
                    onClick={() => {
                      setSelectedUser(item);

                      setNotesEditorValue(
                        item.notes
                          ? RichTextEditor.createValueFromString(
                              item.notes,
                              "html"
                            )
                          : RichTextEditor.createEmptyValue()
                      );
                    }}
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      backgroundColor:
                        selectedUser && selectedUser.email === item.email
                          ? "#F8F8F8"
                          : "#FFF",
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor:
                              colorArray[
                                item.email.toLowerCase().charCodeAt(0) - 97
                              ],
                          }}
                        >
                          {item.email.charAt(0).toUpperCase()}
                        </Avatar>
                      }
                      title={item.name}
                      description={item.email}
                    />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={18} style={{ paddingLeft: 30 }}>
              {selectedUser ? (
                <>
                  <Row>
                    <Col span={12}>
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              style={{
                                backgroundColor:
                                  colorArray[
                                    selectedUser.email
                                      .toLowerCase()
                                      .charCodeAt(0) - 97
                                  ],
                              }}
                            >
                              {selectedUser.email.charAt(0).toUpperCase()}
                            </Avatar>
                          }
                          title={selectedUser.name}
                          description={selectedUser.email}
                        />
                      </List.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Tags">
                        <Select
                          mode="tags"
                          style={{ width: "100%" }}
                          onChange={(tags) => {
                            setSelectedUser({ ...selectedUser, tags });
                          }}
                          tokenSeparators={[","]}
                          value={selectedUser.tags || []}
                        >
                          {selectedUser.tags &&
                            selectedUser.tags.map((tag, i) => (
                              <Option value={tag} key={`tag_${tag}`}>
                                {tag}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Button type="primary" onClick={saveUser}>
                        Save User
                      </Button>
                    </Col>
                  </Row>
                  <Divider />
                  <Tabs onChange={(key) => console.log(key)} type="card">
                    <TabPane tab="Details" key="1">
                      <Descriptions title="Additional User Info" bordered>
                        {additonalUserInfo &&
                          additonalUserInfo.map((propertyKey) => {
                            if (selectedUser.hasOwnProperty(propertyKey)) {
                              const obj = Object.getOwnPropertyDescriptor(
                                { ...selectedUser },
                                propertyKey
                              );
                              return (
                                <Descriptions.Item label={propertyKey}>
                                  {obj && obj.value}
                                </Descriptions.Item>
                              );
                            }
                            return null;
                          })}
                      </Descriptions>
                    </TabPane>
                    <TabPane tab="Survey Results" key="2">
                      <Collapse defaultActiveKey={["0"]} onChange={() => {}}>
                        {rootStore?.surveyResultStore.surveyResults
                          .filter(
                            (surveyResult) =>
                              surveyResult.userId === selectedUser.id
                          )
                          .map((result, i) => {
                            let icon = <AuditOutlined />;

                            if (result.status && result.status === "accept") {
                              icon = (
                                <LikeOutlined style={{ color: "green" }} />
                              );
                            }
                            if (result.status && result.status === "deny") {
                              icon = (
                                <DislikeOutlined style={{ color: "red" }} />
                              );
                            }

                            return (
                              <Panel
                                header={
                                  <>
                                    {selectedUser ? (
                                      <List.Item.Meta
                                        avatar={
                                          <Badge
                                            count={icon}
                                            style={{
                                              padding: "6px",
                                              borderRadius: "50%",
                                              color: "#FFF",
                                              backgroundColor:
                                                result.status === "accept"
                                                  ? "#52c41a"
                                                  : result.status === "deny"
                                                  ? "#f50"
                                                  : "#888",
                                            }}
                                          >
                                            <Avatar
                                              size="large"
                                              style={{
                                                backgroundColor:
                                                  colorArray[
                                                    selectedUser.email
                                                      .toLowerCase()
                                                      .charCodeAt(0) - 97
                                                  ],
                                              }}
                                            >
                                              {selectedUser.email
                                                .charAt(0)
                                                .toUpperCase()}
                                            </Avatar>
                                          </Badge>
                                        }
                                        title={selectedUser.name}
                                        description={selectedUser.email}
                                      />
                                    ) : null}
                                  </>
                                }
                                key={i}
                              >
                                <UIPageCard>
                                  {Object.keys(result.results).map(
                                    (questionResultKey: any) => {
                                      const questionResult =
                                        result.results[questionResultKey];
                                      return (
                                        <div
                                          key={`survey_question_${questionResultKey}`}
                                        >
                                          <h3>
                                            {questionResult &&
                                              questionResult.key}
                                          </h3>
                                          {questionResult ? (
                                            <p>
                                              {Array.isArray(
                                                questionResult.value
                                              )
                                                ? questionResult.value.map(
                                                    (value: string) => (
                                                      <div>
                                                        <Checkbox
                                                          defaultChecked
                                                          disabled
                                                        />{" "}
                                                        {value}
                                                      </div>
                                                    )
                                                  )
                                                : questionResult.value}
                                            </p>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </UIPageCard>
                              </Panel>
                            );
                          })}
                      </Collapse>
                    </TabPane>
                    <TabPane tab="Notes" key="3">
                      <h3>Notes</h3>
                      <RichTextEditor
                        className="editor-area"
                        value={notesEditorValue}
                        onChange={(value: EditorValue) => {
                          setNotesEditorValue(value);
                        }}
                      />
                      <Button type="primary" onClick={saveNotes}>
                        Save Notes
                      </Button>
                    </TabPane>
                  </Tabs>
                </>
              ) : (
                <Empty
                  image={
                    <TeamOutlined style={{ fontSize: 100, opacity: 0.25 }} />
                  }
                  description="No User Selected"
                />
              )}
            </Col>
          </Row>
          <Divider />
          <Row style={{ marginTop: 25 }}>
            <Card title="Import Users via CSV" style={{ width: 500 }}>
              <input type="file" ref={fileInput} />
              <Button onClick={testFunction}>Upload File</Button>
            </Card>
          </Row>
        </Col>
      </CardContainer>
    );
  })
);

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;
const UIActions = styled.div`
  display: flex;
  align-items: center;
`;

const UIPageCard = styled.div`
  height: auto;
  padding: 40px;

  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), -2px 0px 4px rgba(0, 0, 0, 0.05),
    0px -1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-right: 40px;
`;

// const UICreateAction = styled.div`
//   cursor: pointer;
//   transition: transform 250ms ease-in-out;
//   transform-origin: center center;
//   rect {
//     fill: #c3b2f3;
//   }
//   &:hover {
//     transform: scale(1.1);
//   }
//   &:active {
//     transform: scale(0.9);
//   }
// `;

export default UsersView;
