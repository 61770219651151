import { observable } from "mobx";
import { CompanyUserAction } from "../types";
import BaseStore from "./BaseStore";

export default class CompanyUserActionStore extends BaseStore {
  @observable companyUserActions: CompanyUserAction[] = [];
  collection = "companyUserActions";

  hydrate() {
    this.whereConditions = [
      [
        "companyId",
        "in",
        this.rootStore.companyStore.companies.map((company) => company.id),
      ],
    ];
    this.fetch((companyUserActionsToHydrate: CompanyUserAction[]) => {
      this.logger("hydrate:fetch:callback", companyUserActionsToHydrate.length);
      this.setCompanyUsers(companyUserActionsToHydrate);
    });
  }

  setCompanyUsers(companyUserActions: CompanyUserAction[]) {
    this.companyUserActions = companyUserActions;
  }
}
