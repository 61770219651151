import * as React from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import styled from "styled-components";
import { WorkflowIcon, ProfileIcon, DashboardIcon, SurveyIcon } from "./icons";
import {
  LockOutlined,
  // GiftOutlined,
  LogoutOutlined,
  HeartOutlined,
} from "@ant-design/icons";
// import { Menu } from "antd";
import RootStore from "../stores/rootStore";
import { inject, observer } from "mobx-react";
import ReactFitText from "react-fittext";
// import AnnounceKit from "announcekit-react";

type SidebarViewProps = {
  history: History;
  isLoggedIn: boolean;
  isAdmin?: boolean | null;
  rootStore?: RootStore;
};

const Sidebar = styled.section`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  height: 100vh;
  width: 240px;
  padding: 0 25px;
  margin-right: 50px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

const UICompany = styled.div`
  font-weight: 800;
  font-size: 25vw;
  line-height: 41px;
  margin-top: 35px;
  margin-bottom: 20px;
  color: #272343;
  max-width: 220px;
`;

const UICurrentUser = styled.div``;
const UIName = styled.div<{ active?: boolean }>`
  font-weight: ${({ active }) => (active ? 600 : 400)};
  font-size: 18px;
  color: ${({ active }) => (active ? "#272343" : "#5a5770")};
`;

const NavLinks = styled.div`
  height: 100%;
`;
const NavLink = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  font-size: 18px;
  a {
    color: ${({ active }) => (active ? "#272343" : "#5a5770")};
    height: 30px;
    line-height: 30px;
  }
`;
const NavLinkIcon = styled.div<{ active?: boolean }>`
  margin-right: 20px;
  height: auto;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: auto;
    width: 20px;
    path {
      fill: ${({ active }) => (active ? "#272343" : "#5a5770")};
    }
    rect {
      opacity: ${({ active }) => (active ? 1 : 0)};
    }
  }
`;

const SidebarView: React.FunctionComponent<
  SidebarViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ rootStore, history, isLoggedIn, isAdmin }) => {
    const logoutUser = () => {
      rootStore?.appUserStore.signOut();
    };

    return (
      <Sidebar>
        {rootStore?.companyStore?.companies &&
          rootStore?.companyStore?.companies.length > 0 &&
          rootStore?.companyStore?.companies[0].name && (
            <UICurrentUser>
              <UICompany>
                <ReactFitText>
                  <div>{rootStore?.companyStore?.companies[0].name}</div>
                </ReactFitText>
              </UICompany>
            </UICurrentUser>
          )}
        <NavLinks>
          {!isLoggedIn && (
            <NavLink active={history && history.location.pathname === "/login"}>
              <Link to="/login">Login</Link>
            </NavLink>
          )}
          {!isLoggedIn && (
            <NavLink
              active={history && history.location.pathname === "/signup"}
            >
              <Link to="/signup">Sign up</Link>
            </NavLink>
          )}
          {isLoggedIn && (
            <NavLink
              active={history && history.location.pathname === "/dashboard"}
            >
              <NavLinkIcon
                active={history && history.location.pathname === "/dashboard"}
              >
                <DashboardIcon />
              </NavLinkIcon>
              <Link to="/dashboard">Dashboard</Link>
            </NavLink>
          )}
          {isLoggedIn && (
            <NavLink
              active={history && history.location.pathname.includes("/surveys")}
            >
              <NavLinkIcon
                active={
                  history && history.location.pathname.includes("/surveys")
                }
              >
                <SurveyIcon />
              </NavLinkIcon>
              <Link to="/surveys">Surveys</Link>
            </NavLink>
          )}
          {/* {isLoggedIn && (
          <NavLink
            active={history && history.location.pathname === "/interviews"}
          >
            <NavLinkIcon
              active={history && history.location.pathname === "/interviews"}
            >
              <InterviewingIcon />
            </NavLinkIcon>
            <Link to="/interviews">Interviews</Link>
          </NavLink>
        )} */}
          {isLoggedIn && (
            <NavLink
              active={
                history && history.location.pathname.includes("/workflows")
              }
            >
              <NavLinkIcon
                active={
                  history && history.location.pathname.includes("/workflows")
                }
              >
                <WorkflowIcon />
              </NavLinkIcon>
              <Link to="/workflows">Journeys</Link>
            </NavLink>
          )}
          {isLoggedIn && (
            <NavLink active={history && history.location.pathname === "/users"}>
              <NavLinkIcon
                active={history && history.location.pathname === "/users"}
              >
                <ProfileIcon />
              </NavLinkIcon>
              <Link to="/users">Users</Link>
            </NavLink>
          )}

          {/* TODO add settings background when active */}

          {/* {isLoggedIn && (
            <NavLink
              active={history && history.location.pathname === "/settings"}
            >
              <NavLinkIcon
                active={history && history.location.pathname === "/settings"}
              >
                <SettingOutlined />
              </NavLinkIcon>
              <Link to="/settings">Settings</Link>
            </NavLink>
          )} */}
          {isLoggedIn && isAdmin && (
            <NavLink active={history && history.location.pathname === "/admin"}>
              <NavLinkIcon
                active={history && history.location.pathname === "/admin"}
              >
                <LockOutlined />
              </NavLinkIcon>
              <Link to="/admin">Admin</Link>
            </NavLink>
          )}
        </NavLinks>

        {/* {isLoggedIn && (
          <NavLink>
            <NavLinkIcon>
              <GiftOutlined />
            </NavLinkIcon>
            <AnnounceKit widget="https://announcekit.app/widgets/v2/1D6dQk">
              <UIName>What's new</UIName>
            </AnnounceKit>
          </NavLink>
        )} */}

        {isLoggedIn && (
          <NavLink active={history && history.location.pathname === "/account"}>
            <NavLinkIcon
              active={history && history.location.pathname === "/account"}
            >
              <HeartOutlined />
            </NavLinkIcon>
            <Link to="/account">
              {rootStore?.appUserStore.appUser && (
                <UICurrentUser>
                  <UIName
                    active={history && history.location.pathname === "/account"}
                  >
                    {rootStore?.appUserStore.appUser.name || "Account"}
                  </UIName>
                </UICurrentUser>
              )}
            </Link>
          </NavLink>
        )}

        {isLoggedIn && (
          <NavLink active={history && history.location.pathname === "/logout"}>
            <NavLinkIcon
              active={history && history.location.pathname === "/logout"}
            >
              <LogoutOutlined />
            </NavLinkIcon>
            <NavLink onClick={logoutUser} style={{ marginBottom: -20 }}>
              Logout
            </NavLink>
          </NavLink>
        )}
      </Sidebar>
    );
  })
);

export default withRouter(SidebarView);
