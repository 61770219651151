import { observable, computed } from "mobx";
import app, { auth } from "firebase/app";
import { loadStripe } from "@stripe/stripe-js";
import { AppUser } from "../types";
import BaseStore from "./BaseStore";
import moment from "moment";

// Move this to env vars...
const INTERCOM_APP_ID = "lmvj0uqx";

export default class AppUserStore extends BaseStore {
  @observable appUser?: AppUser;
  collection = "users";

  @computed get isLoggedIn(): boolean | undefined {
    return Boolean(
      this.appUser &&
        this.appUser.id &&
        this.rootStore.firebase?.auth.currentUser
    );
  }

  hydrate(userId: string) {
    this.whereConditions = [["id", "==", userId]];
    this.fetch((appUserToHydrate: AppUser[]) => {
      if (appUserToHydrate.length > 0) {
        this.logger("hydrate:fetch:callback", appUserToHydrate);
        this.setAppUser(appUserToHydrate[0]);

        // Fetch users companies...
        this.rootStore.companyStore.hydrate();
      } else {
        this.logger("hydrate:fetch:callback", "ERROR:appUserToHydrate empty");
      }
    });
  }

  setAppUser(appUser?: AppUser) {
    this.appUser = appUser;

    /* set intercom info */
    if (["development", "test"].indexOf(process.env.NODE_ENV) === -1) {
      (window as any).Intercom("boot", {
        name: appUser?.name,
        email: appUser?.email,
        user_id: appUser?.id,
        app_id: INTERCOM_APP_ID,
        created_at: moment(appUser?.createdAt).unix(),
      });
    }

    if (this.rootStore.initializing) {
      this.rootStore.initializing = false;
    }
  }

  updateAppUser(appUser: AppUser) {
    return this.update(appUser);
  }

  /*
   * Auth Methods
   */
  listenForAuthChanges() {
    this.rootStore.firebase?.auth.onAuthStateChanged((authUser: any) => {
      this.logger("listenForAuthChanges", "on auth change");
      if (authUser) {
        this.hydrate(authUser.uid);
      } else {
        this.setAppUser(undefined);
      }
    });
  }

  signUpWihEmailPassword(
    email: string,
    password: string
  ): Promise<app.auth.UserCredential> | undefined {
    return this.rootStore.firebase?.auth.createUserWithEmailAndPassword(
      email,
      password
    );
  }

  loginWihEmailPassword(
    email: string,
    password: string
  ): Promise<app.auth.UserCredential> | undefined {
    return this.rootStore.firebase?.auth.signInWithEmailAndPassword(
      email,
      password
    );
  }

  signOut(): Promise<void> | undefined {
    this.setAppUser(undefined);
    return this.rootStore.firebase?.auth.signOut();
  }

  /* Stripe Methods */
  goToStripePortal = () => {
    this.rootStore?.firebase?.cloudFns
      .httpsCallable("getStripePortalURL")({})
      .then((result: { data: { error: string; session: { url: string } } }) => {
        if (!result.data.error) {
          window.location.href = result.data.session.url;
        }
      });
  };

  goToStripeCheckout = () => {
    // Move this to env vars...
    const stripePromise = loadStripe(
      process.env.NODE_ENV === "production"
        ? "pk_live_51HSvU6GtuGcIClvm1CmhMQV49ezePUIU85enWT50r6S1jTKohBXt96ohRoYguXCJF9Hj6u5Ng7l6hbMqfAT1854Z00tA6yFuej"
        : "pk_test_51HSvU6GtuGcIClvm5o2DoUmOgpTDKqViZifURcz3hHBjMkTzm9ZnItgjO1jEuQvrdhvN1sU4pjgkuejsUS15mlmP00Loc1SzX0"
    );

    stripePromise.then((stripe) => {
      if (stripe) {
        this.rootStore?.firebase?.cloudFns
          .httpsCallable("getStripeCheckoutURL")({})
          .then(
            (result: { data: { error: string; session: { id: string } } }) => {
              if (!result.data.error) {
                stripe
                  .redirectToCheckout({
                    sessionId: result.data.session.id,
                  })
                  .then((result: any) => {
                    console.log(result);
                  });
              }
            }
          );
      }
    });
  };
}
