import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Editor } from "@tinymce/tinymce-react";
// import tinymce from "tinymce";
import { Form, Input, Button, Row, Col, Popover, Typography } from "antd";
import { History } from "history";
import { Email } from "../types";
import RootStore from "../stores/rootStore";
import PageLoading from "../components/pageLoading";

const { Paragraph, Title } = Typography;

type EmailEditorViewProps = {
  rootStore?: RootStore;
  history: History;
  emailId?: string;
  onSave?: () => void;
  match: {
    params: {
      companyId: string;
      emailId: string;
    };
  };
};

const EmailEditorView: React.FunctionComponent<
  EmailEditorViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ rootStore, history, emailId, match: { params }, onSave }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [email, setEmail] = React.useState<Email>();
    const [decodedHTML, setDecodedHTML] = React.useState<string>("");
    const activeEmailId = emailId ? emailId : params.emailId;

    const allEmails = rootStore?.emailStore.emails;
    React.useEffect(() => {
      console.log("Looking for email:", activeEmailId);
      if (!activeEmailId) return;
      const foundEmail = allEmails?.find((email) => email.id === activeEmailId);
      console.log("Found this", foundEmail);
      if (foundEmail && !email) {
        if (foundEmail.encodedHTML) {
          const html = foundEmail.encodedHTML;
          if (html) {
            setDecodedHTML(html);
          }
        }

        setIsLoading(false);
        setEmail(foundEmail);
        console.log(foundEmail);

        // tinymce.init({
        //   setup: (editor) => {
        //     editor.ui.registry.addButton("surveyLink", {
        //       text: "Survey Link",
        //       tooltip: "Insert Link to Survey",
        //       onAction: function () {
        //         var html = `<a href="#">Hello!</a>`;
        //         editor.insertContent(html);
        //       },
        //     });
        //   },
        // });
      }
    }, [activeEmailId, email, allEmails]);

    if (isLoading || !email) return <PageLoading />;

    console.log("Rendering EmailEditor");

    const handleOnSave = () => {
      rootStore?.emailStore.updateEmail({
        ...email,
        encodedHTML: decodedHTML,
      } as Email);
      if (onSave) onSave();
    };

    let surveyLinkList: { title: string; value: string }[] = [];
    rootStore?.surveyStore.surveys.forEach((survey: any) => {
      surveyLinkList.push({
        title: "Survey > " + survey.title,
        value:
          window.location.protocol +
          "//survey." +
          window.location.host +
          "/" +
          survey.id +
          "/%USER_ID%",
      });
    });

    return (
      <div>
        <Form layout="vertical">
          <Form.Item label="Subject">
            <Input
              type="text"
              defaultValue={email.title}
              onChange={(e) => {
                setEmail({ ...email, title: e.target.value });
              }}
            />
          </Form.Item>
        </Form>
        <Editor
          apiKey="ra66cjb2lj6vvyis7phm0malvlwes42un36dvmm4gs7uktmc"
          initialValue={decodedHTML}
          init={{
            height: window.innerHeight - 175,
            menubar: true,
            plugins: [
              "advlist advcode autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | surveyLink | help",
            link_list: surveyLinkList,
            link_title: false,
          }}
          onEditorChange={(content: string) => setDecodedHTML(content)}
        />
        <Row>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={handleOnSave}
          >
            Save Email
          </Button>
          <Popover
            content={
              <div>
                {rootStore?.surveyStore.surveys.map((survey) => {
                  return (
                    <Row style={{ maxWidth: 300 }}>
                      <Col>
                        <Title level={4}>{survey.title}</Title>
                        <Paragraph
                          ellipsis={true}
                          copyable={{
                            text:
                              window.location.protocol +
                              "//survey." +
                              window.location.host +
                              "/" +
                              survey.id +
                              "/%USER_ID%",
                          }}
                        >
                          {window.location.protocol +
                            "//survey." +
                            window.location.host +
                            "/" +
                            survey.id +
                            "/%USER_ID%"}
                        </Paragraph>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            }
            title="My Surveys"
            trigger="click"
          >
            <Button style={{ marginTop: 10 }} type="link">
              My Surveys
            </Button>
          </Popover>
        </Row>
      </div>
    );
  })
);

export default withRouter(EmailEditorView);
