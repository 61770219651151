import { Button } from "antd";
import * as React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { EmailPart } from "../../types";
import RootStore from "../../stores/rootStore";

const ImageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
`;

type ImageViewProps = {
  rootStore?: RootStore;
  part: EmailPart;
  update: (part: EmailPart) => void;
};

const ImageView: React.FunctionComponent<ImageViewProps> = ({
  rootStore,
  part,
  update,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const fileInput = React.useRef<HTMLInputElement>(null);

  const uploadFile = () => {
    if (
      rootStore &&
      fileInput &&
      fileInput.current &&
      fileInput.current!.files &&
      fileInput.current!.files.length > 0
    ) {
      setIsLoading(true);
      rootStore.surveyStore
        .uploadPhotoToSurveyNode(fileInput.current.files[0])
        .then((url: string) => {
          update({ ...part, value: { url } });
          setIsLoading(false);
        });
    }
  };

  return (
    <ImageContainer style={{ backgroundColor: "transparent" }}>
      {part.value && part.value.url ? (
        <img
          style={{ maxWidth: "100%", marginBottom: 10 }}
          src={part.value.url}
          alt="survey"
        />
      ) : null}
      {isLoading && <LoadingOutlined style={{ fontSize: 30 }} spin />}
      <input accept="image/*" ref={fileInput} type="file" />
      <Button onClick={uploadFile}>Upload</Button>
    </ImageContainer>
  );
};

type ImageRenderedViewProps = {
  part: EmailPart;
};

const ImageRenderedView: React.FunctionComponent<ImageRenderedViewProps> = ({
  part,
}) => {
  if (part.value && part.value.url) {
    return (
      <img
        style={{ maxWidth: "100%", marginBottom: 10 }}
        src={part.value.url}
        alt="survey"
      />
    );
  } else {
    return null;
  }
};

export default ImageView;

export { ImageRenderedView };
