import * as React from "react";
import styled from "styled-components";
import { Button, Row, Col, Input, Select, Empty } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";

const { Option } = Select;

export const DataUsersFilterSidebarItem = () => {
  return (
    <UISideBarConatiner>
      <FilterOutlined style={{ marginRight: 10, fontSize: 24, marginTop: 2 }} />
      <UISideBarInfoConatiner>
        <h3>User Filter</h3>
        <p style={{ color: "#888" }}>
          Pulls in users imported into your BuildBetter company account.
        </p>
      </UISideBarInfoConatiner>
    </UISideBarConatiner>
  );
};

type Filter = {
  key: string;
  operator: string;
  value: string;
};

type PropertiesWithFilter = {
  filters: { key: string; operator: string; value: string }[];
};

const OperatorTypes = ["is", "is-not", "is-in-array", "not-in-array"];

const updateFilterInProperties = (
  properties: PropertiesWithFilter,
  index: number,
  updatedFilter: Filter
): PropertiesWithFilter => {
  let filters = properties.filters || [];
  filters[index] = updatedFilter;
  return { ...properties, filters };
};

export const DataUsersFilterNode = ({
  node,
  attachPropertyToNode,
}: {
  node: {
    id: number;
    properties: PropertiesWithFilter;
  };
  attachPropertyToNode: (id: number, properties: {}) => void;
}) => {
  return (
    <UIContainer>
      {node.properties && node.properties.filters ? (
        <UIFlowNodeContent>
          <Col span={24}>
            {(node.properties.filters || []).length > 0 ? (
              <Row gutter={12}>
                <Col span="8">
                  <b>Property</b>
                </Col>
                <Col span="8">
                  <b>Operator</b>
                </Col>
                <Col span="8">
                  <b>Value</b>
                </Col>
              </Row>
            ) : (
              <Empty />
            )}
            {(node.properties.filters || []).map(
              (filter: Filter, i: number) => {
                return (
                  <Row
                    gutter={12}
                    key={`node_${node.id}_filter_${i}`}
                    style={{ marginBottom: 5 }}
                  >
                    <Col
                      span="8"
                      onClick={(e) => e.stopPropagation()}
                      onMouseUp={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <Input
                        defaultValue={filter.key}
                        onBlur={(e) => {
                          let updatedFilter = filter;
                          filter.key = e.target.value;
                          attachPropertyToNode(
                            node.id,
                            updateFilterInProperties(
                              node.properties,
                              i,
                              updatedFilter
                            )
                          );
                        }}
                      />
                    </Col>
                    <Col
                      span="8"
                      onClick={(e) => e.stopPropagation()}
                      onMouseUp={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <Select
                        defaultValue={filter.operator}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          let updatedFilter = filter;
                          filter.operator = value;
                          attachPropertyToNode(
                            node.id,
                            updateFilterInProperties(
                              node.properties,
                              i,
                              updatedFilter
                            )
                          );
                        }}
                      >
                        {OperatorTypes.map((operator: string) => {
                          console.log(capitalize(operator.replace(/-/gi, " ")));
                          return (
                            <Option
                              value={operator}
                              key={`operator_type_${operator}`}
                            >
                              {capitalize(operator.replace(/-/gi, " "))}
                            </Option>
                          );
                        })}
                      </Select>
                    </Col>
                    <Col
                      span="8"
                      onClick={(e) => e.stopPropagation()}
                      onMouseUp={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <Input
                        defaultValue={filter.value}
                        onBlur={(e) => {
                          let updatedFilter = filter;
                          filter.value = e.target.value;
                          attachPropertyToNode(
                            node.id,
                            updateFilterInProperties(
                              node.properties,
                              i,
                              updatedFilter
                            )
                          );
                        }}
                      />
                    </Col>
                  </Row>
                );
              }
            )}
          </Col>
        </UIFlowNodeContent>
      ) : null}
      <UIFlowNodeFooterBar>
        <Button
          onClick={(e) => {
            let filters = node.properties.filters || [];
            attachPropertyToNode(node.id, {
              ...node.properties,
              filters: filters.concat({ key: "", operator: "", value: "" }),
            });

            // console.log(node.properties.filters);
            // console.log({
            //   filters: [
            //     ...(node.properties.filters || []),
            //     { key: "", operator: "", value: "" },
            //   ],
            // });
            // attachPropertyToNode(node.id, {
            //   filters: [
            //     ...(node.properties.filters || []),
            //     { key: "", operator: "", value: "" },
            //   ],
            // });
          }}
        >
          Add Filter Row
        </Button>
      </UIFlowNodeFooterBar>
    </UIContainer>
  );
};

const UIContainer = styled.div`
  min-width: 400px;
`;

const UISideBarConatiner = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  border: #eee 1px solid;
  border-radius: 5px;
  width: 200px;
  align-items: flex-start;
`;

const UISideBarInfoConatiner = styled.div`
  width: 250px;
`;

const UIFlowNodeContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const UIFlowNodeFooterBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: #eee 1px solid;
  padding: 10px;
`;
