import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import moment from "moment";
import RootStore from "../stores/rootStore";
import CardContainer from "../components/cardContainer";
import { ProfileIcon } from "../components/icons";
import { Button, Alert, Divider } from "antd";

type SettingsViewProps = {
  history: History;
  rootStore?: RootStore;
};

const SettingsView: React.FunctionComponent<
  SettingsViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ history, rootStore }) => {
    return (
      <CardContainer>
        <UIHeader>
          <UITitle>
            <ProfileIcon /> <h2>Account</h2>
          </UITitle>
          <UIActions>
            {/* <UICreateAction onClick={handleCreateWorkflow}>
              <CreateIcon />
            </UICreateAction> */}
          </UIActions>
        </UIHeader>
        <Divider />
        <div>
          {rootStore &&
          rootStore?.companyStore.companies &&
          rootStore?.companyStore.companies[0] &&
          rootStore?.companyStore.companies[0].subscription_expiration_date &&
          moment
            .unix(
              parseInt(
                rootStore?.companyStore.companies[0]
                  .subscription_expiration_date
              )
            )
            .isAfter(moment()) ? (
            <Alert
              type="success"
              description={`Expires on ${moment
                .unix(
                  parseInt(
                    rootStore?.companyStore.companies[0]
                      .subscription_expiration_date
                  )
                )
                .toISOString()}`}
              message="Active Subscription"
            />
          ) : (
            <Alert type="error" message="No active Subscription" />
          )}
          {/* <Button onClick={goToStripePortal}>Go to billing portal</Button>
          <Button onClick={goToStripeCheckout}>Go to checkout</Button> */}
          <p>Want to cancel? Contact our support.</p>
          <Button>Contact support</Button>
        </div>
      </CardContainer>
    );
  })
);

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;
const UIActions = styled.div`
  display: flex;
  align-items: center;
`;

export default withRouter(SettingsView);
