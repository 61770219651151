import * as React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { Divider, Row, Tooltip, Switch } from "antd";
import {
  DragOutlined,
  LineHeightOutlined,
  FontSizeOutlined,
  AlignCenterOutlined,
  UnorderedListOutlined,
  FormOutlined,
  EditOutlined,
  DeleteOutlined,
  VerticalAlignMiddleOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from "react-sortable-hoc";
import { EmailPart } from "../types";
import PageLoading from "../components/pageLoading";
import { Survey } from "../types";
import RootStore from "../stores/rootStore";

import Title from "../components/emailParts/Title";
import SubTitle from "../components/emailParts/SubTitle";
import Paragraph from "../components/emailParts/Paragraph";
import MultipleChoiceQuestion from "../components/emailParts/MultipleChoiceQuestion";
import SingleChoiceQuestion from "../components/emailParts/SingleChoiceQuestion";
import UserInputParagraphView from "../components/emailParts/UserInputParagraph";
import UserInputTextView from "../components/emailParts/UserInputText";
import PageBreakView from "../components/emailParts/PageBreak";
import ImageView from "../components/emailParts/Image";

const EmailPartsConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const EmailConatiner = styled.div`
  width: 100%;
`;

const EmailContent = styled.div`
  width: 100%;
  background-color: #fff;
`;

const RequiredPartMoveHandle = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 70px;
  background-color: #fff;
  border: #eee 1px solid;
  border-radius: 5px;
  padding: 3px 6px;
  font-size: 8pt;
  color: #888;
  height: 30px;
  justify-content: center;
  align-items: center;
  .ant-switch {
    margin-left: 5px;
  }
`;

const EmailPartMoveHandle = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border: #eee 1px solid;
  border-radius: 5px;
  padding: 3px 6px;
  cursor: move;
`;

const DeleteButtonConatiner = styled.div`
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0;
  right: 35px;
  background-color: #fff;
  border: #eee 1px solid;
  border-radius: 5px;
  padding: 3px 6px;
`;

const SurveyPartContainer = styled.li`
  position: relative;
  list-style: none;
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  &:hover ${EmailPartMoveHandle}, &:hover ${DeleteButtonConatiner} {
    display: block;
  }
  &:hover ${RequiredPartMoveHandle} {
    display: flex;
  }
`;

type SurveyViewProps = {
  rootStore?: RootStore;
  survey: Survey;
  setSurvey: (survey: Survey) => void;
};

const SurveyView = inject("rootStore")(
  observer(({ rootStore, survey, setSurvey }: SurveyViewProps) => {
    const addSurveyPart = (type: string) => {
      if (survey) {
        const newPart = { id: uuid(), type, value: {} } as EmailPart;
        let content = survey.content.concat(newPart);
        setSurvey({ ...survey, content });
      }
    };

    const updateEmailPart = (part: EmailPart): void => {
      if (survey) {
        let content = survey.content;

        const itemIndex: number = content.findIndex((p) => p.id === part.id);
        let items: EmailPart[] = [...content];
        items[itemIndex] = part;

        setSurvey({ ...survey, content: items });
      }
    };

    const deleteEmailPart = (part: EmailPart): void => {
      if (survey) {
        let content = survey.content;

        const itemIndex: number = content.findIndex((p) => p.id === part.id);
        let items: EmailPart[] = [...content];
        delete items[itemIndex];
        items = items.filter(Boolean);

        console.log(items);

        setSurvey({ ...survey, content: items });
      }
    };

    const DeleteButton = ({
      onClick,
    }: {
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    }) => {
      return (
        <DeleteButtonConatiner onClick={onClick}>
          <Tooltip title="Delete">
            <DeleteOutlined />
          </Tooltip>
        </DeleteButtonConatiner>
      );
    };

    const DragHandle = SortableHandle(() => <DragOutlined />);
    const SortableItem = SortableElement(({ value }: { value: EmailPart }) => {
      let renderedNodeElement: React.ReactElement | null = null;
      if (value.type === "title") {
        renderedNodeElement = <Title part={value} update={updateEmailPart} />;
      } else if (value.type === "subtitle") {
        renderedNodeElement = (
          <SubTitle part={value} update={updateEmailPart} />
        );
      } else if (value.type === "paragraph") {
        renderedNodeElement = (
          <Paragraph part={value} update={updateEmailPart} />
        );
      } else if (value.type === "MC_QUESTION") {
        renderedNodeElement = (
          <MultipleChoiceQuestion part={value} update={updateEmailPart} />
        );
      } else if (value.type === "UI_SC_QUESTION") {
        renderedNodeElement = (
          <SingleChoiceQuestion part={value} update={updateEmailPart} />
        );
      } else if (value.type === "UI_PARAGRAPH") {
        renderedNodeElement = (
          <UserInputParagraphView part={value} update={updateEmailPart} />
        );
      } else if (value.type === "UI_TEXT") {
        renderedNodeElement = (
          <UserInputTextView part={value} update={updateEmailPart} />
        );
      } else if (value.type === "PAGE_BREAK") {
        renderedNodeElement = (
          <PageBreakView part={value} update={updateEmailPart} />
        );
      } else if (value.type === "image") {
        renderedNodeElement = (
          <ImageView
            part={value}
            update={updateEmailPart}
            rootStore={rootStore}
          />
        );
      }
      if (!renderedNodeElement) return null;
      return (
        <SurveyPartContainer className="sortable-item">
          {renderedNodeElement}
          <DeleteButton
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              deleteEmailPart(value);
            }}
          />
          {["MC_QUESTION", "UI_SC_QUESTION", "UI_PARAGRAPH", "UI_TEXT"].indexOf(
            value.type
          ) > -1 && (
            <RequiredPartMoveHandle>
              Required
              <Switch
                size="small"
                checked={value.value.required}
                disabled={value.id === "bb_email_address"}
                onChange={(checked) =>
                  updateEmailPart({
                    ...value,
                    value: { ...value.value, required: checked },
                  })
                }
              />
            </RequiredPartMoveHandle>
          )}
          <EmailPartMoveHandle>
            <DragHandle />
          </EmailPartMoveHandle>
        </SurveyPartContainer>
      );
    });

    const SortableList = SortableContainer(
      ({ items }: { items: EmailPart[] }) => {
        return (
          <ul style={{ margin: 0, padding: 0 }} className="sortable-conatiner">
            {items.map((value: EmailPart, index: number) => (
              <SortableItem
                key={`item-${value.type}-${index}`}
                index={index}
                value={value}
              />
            ))}
          </ul>
        );
      }
    );

    const onSortEnd = ({
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    }) => {
      if (survey) {
        let content = survey.content;
        content = arrayMove(content, oldIndex, newIndex);
        setSurvey({ ...survey, content });
      }
    };
    if (!survey) {
      return <PageLoading />;
    }
    return (
      <div>
        <Row gutter={12}>
          <EmailConatiner>
            <EmailContent>
              <SortableList
                items={survey.content}
                onSortEnd={onSortEnd}
                useDragHandle={true}
              />
            </EmailContent>
            <Divider />
            <EmailPartsConatiner>
              <UIPartButton onClick={() => addSurveyPart("title")}>
                <LineHeightOutlined />
                <div>Title</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("subtitle")}>
                <FontSizeOutlined />
                <div>Sub-Title</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("paragraph")}>
                <AlignCenterOutlined />
                <div>Paragraph</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("image")}>
                <PictureOutlined />
                <div>Image</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("MC_QUESTION")}>
                <UnorderedListOutlined />
                <div>Multiple Choice</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("UI_SC_QUESTION")}>
                <UnorderedListOutlined />
                <div>Single Choice</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("UI_TEXT")}>
                <EditOutlined />
                <div>Text Input</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("UI_PARAGRAPH")}>
                <FormOutlined />
                <div>Paragraph Input</div>
              </UIPartButton>
              <UIPartButton onClick={() => addSurveyPart("PAGE_BREAK")}>
                <VerticalAlignMiddleOutlined />
                <div>Page Break</div>
              </UIPartButton>
              {/* <Button onClick={() => addEmailPart("cta")}>Add CTA</Button>
                <Button onClick={() => addEmailPart("image")}>Add Image</Button> */}
            </EmailPartsConatiner>
          </EmailConatiner>
        </Row>
        <Divider />
      </div>
    );
  })
);

const UIPartButton = styled.div`
  cursor: pointer;
  height: 80px;
  width: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px rgba(0, 0, 0, 0.05);
  font-size: 11px;
  &:hover {
    background-color: #272343;
    color: #fff;
    svg {
      fill: #fff;
    }
  }
  .anticon {
    transform: scale(2);
    margin-bottom: 10px;
  }
`;

export default SurveyView;
