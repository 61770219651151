import * as React from "react";
import styled from "styled-components";
import { EmailPart } from "../../types";

const EmailPartSubTitleRendered = styled.p`
  width: 100%;
  font-size: 12pt;
  font-weight: 500;
  padding: 5px;
  margin-bottom: 10px;
  color: rgba(39, 35, 67, 0.75);
`;

const EmailPartSubTitle = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  font-size: 12pt;
  font-weight: 500;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: transparent 2px dashed;
  color: rgba(39, 35, 67, 0.75);
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

type SubTitleViewProps = {
  part: EmailPart;
  update?: (part: EmailPart) => void;
};

const SubTitleView: React.FunctionComponent<SubTitleViewProps> = ({
  part,
  update,
}) => {
  return (
    <EmailPartSubTitle
      placeholder={"SubTitle"}
      defaultValue={part.value.title}
      onBlur={(e) =>
        update && update({ ...part, value: { title: e.target.value } })
      }
    />
  );
};

const SubTitleRenderedView: React.FunctionComponent<SubTitleViewProps> = ({
  part,
}) => {
  return (
    <EmailPartSubTitleRendered className="subtitle">
      {part.value.title}
    </EmailPartSubTitleRendered>
  );
};

export { SubTitleRenderedView };

export default SubTitleView;
