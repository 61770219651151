import * as React from "react";
import { Input, Button, Alert, Form, Divider } from "antd";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { History } from "history";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import PageLoading from "../components/pageLoading";

type LoginViewProps = {
  history: History;
  rootStore?: RootStore;
};

const FormConatiner = styled.div`
  max-width: 400px;
  margin: 40px auto 40px auto;
`;

const LoginView: React.FunctionComponent<
  LoginViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ rootStore, history }) => {
    const [loginError, setLoginError] = React.useState<string | null>(null);
    const [emailInputValue, setEmailInputValue] = React.useState<string>();
    const [passwordInputValue, setPasswordInputValue] = React.useState<
      string
    >();

    const handleLogin = () => {
      setLoginError(null); //reset errors
      if (emailInputValue && passwordInputValue) {
        rootStore?.appUserStore
          .loginWihEmailPassword(emailInputValue, passwordInputValue)
          ?.then(() => {
            // Go to dashboard
            console.log("Go to dashboard");

            // Analytics
            rootStore?.analytics?.logEvent("login", {}); // <-- @spencer

            history.push("/dashboard");
          })
          .catch((error) => {
            console.log("Login Error", error);
            setLoginError(error.message);
          });
      } else {
        setLoginError("Email or Password Empty");
      }
    };

    if (rootStore?.appUserStore.isLoggedIn) {
      if ((window as any).location.href.indexOf("zapierRedirect") > -1) {
        // const form = document.createElement('form');
        // form.method = "POST";
        // form.action = "/auth";

        // const params = {

        // };

        // for (const key in params) {
        //   if (params.hasOwnProperty(key)) {
        //     const hiddenField = document.createElement('input');
        //     hiddenField.type = 'hidden';
        //     hiddenField.name = key;
        //     hiddenField.value = params[key];

        //     form.appendChild(hiddenField);
        //   }
        // }

        // document.body.appendChild(form);
        // form.submit();
        var urlParams = new URLSearchParams(window.location.search);
        const redirect_uri = urlParams.get("redirect_uri");
        const state = urlParams.get("state");

        window.location.href = `https://us-central1-build-better-app-staging.cloudfunctions.net/zapierAuth?uid=${rootStore?.appUserStore?.appUser?.id}&redirect_uri=${redirect_uri}&state=${state}`;

        return <PageLoading />;
      } else {
        return <Redirect to={"/dashboard"} />;
      }
    }

    return (
      <FormConatiner>
        <h2>Login</h2>
        <Form layout="vertical">
          {loginError && <Alert message={loginError} type="error" />}
          <Form.Item label="Email Address">
            <Input
              placeholder="Email Address"
              onChange={(e) => setEmailInputValue(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input
              placeholder="Password"
              type="password"
              onChange={(e) => setPasswordInputValue(e.target.value)}
            />
          </Form.Item>
          <Button type="primary" onClick={handleLogin}>
            Login
          </Button>
        </Form>
        <Divider />
        <div>
          Build Better is currently invite only,{" "}
          <a href="https://www.buildbetter.app">request invite</a>.
        </div>
      </FormConatiner>
    );
  })
);

export default withRouter(LoginView);
