import * as React from "react";
import styled from "styled-components";
import { Row, Col, Select } from "antd";
import { PauseCircleOutlined } from "@ant-design/icons";
import { capitalize, toNumber } from "lodash";

const { Option } = Select;

const TIME_INTERVAL_TYPES = ["minute", "hour", "day"];

export const BreakpointSidebarItem = () => {
  return (
    <UISideBarConatiner>
      <PauseCircleOutlined
        style={{ marginRight: 10, fontSize: 24, marginTop: 2 }}
      />
      <UISideBarInfoConatiner>
        <h3>Pause & Wait</h3>
        <p style={{ color: "#888" }}>
          Pauses and wait specified amount of time before continuing.
        </p>
      </UISideBarInfoConatiner>
    </UISideBarConatiner>
  );
};

export const BreakpointNode = ({
  node,
  attachPropertyToNode,
}: {
  node: {
    id: number;
    properties: { intervalAmount: number; intervalType: string };
  };
  attachPropertyToNode: (id: number, properties: {}) => void;
}) => {
  return (
    <UIContainer>
      <UIFlowNodeContent>
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={12}>
            <Select
              value={
                node.properties.intervalAmount
                  ? String(node.properties.intervalAmount)
                  : ""
              }
              style={{ width: "100%" }}
              onChange={(value: string) => {
                attachPropertyToNode(node.id, {
                  intervalAmount: toNumber(value),
                });
              }}
            >
              {new Array(60).fill("").map((value: string, i: number) => {
                return (
                  <Option value={i + 1} key={`value_${i + 1}`}>
                    {i + 1}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col span={12}>
            <Select
              value={node.properties.intervalType}
              style={{ width: "100%" }}
              onChange={(intervalType: string) => {
                attachPropertyToNode(node.id, { intervalType });
              }}
            >
              {TIME_INTERVAL_TYPES.map((interval: string) => {
                return (
                  <Option value={interval} key={`interval_type_${interval}`}>
                    {capitalize(interval)}
                    {node.properties.intervalAmount &&
                      node.properties.intervalAmount > 1 &&
                      "s"}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </UIFlowNodeContent>
    </UIContainer>
  );
};

const UIContainer = styled.div`
  min-width: 400px;
`;

const UISideBarConatiner = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  border: #eee 1px solid;
  border-radius: 5px;
  width: 200px;
  align-items: flex-start;
`;

const UISideBarInfoConatiner = styled.div`
  width: 250px;
`;

const UIFlowNodeContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;
