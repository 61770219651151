import * as React from "react";
import { Input, Button, Divider, Form, Alert } from "antd";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { History } from "history";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
// import { auth } from "firebase";

type SignUpViewProps = {
  history: History;
  rootStore?: RootStore;
};

const FormConatiner = styled.div`
  max-width: 400px;
  margin: 40px auto 40px auto;
`;

const SignUpView: React.FunctionComponent<
  SignUpViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ rootStore, history }) => {
    if (rootStore?.appUserStore.isLoggedIn) {
      return <Redirect to={"/dashboard"} />;
    }

    const [signUpError, setSignUpError] = React.useState<string | null>(null);
    const [inviteInputValue, setInviteInputValue] = React.useState<string>();
    const [emailInputValue, setEmailInputValue] = React.useState<string>();
    const [passwordInputValue, setPasswordInputValue] = React.useState<
      string
    >();
    const [confirmInputValue, setConfirmInputValue] = React.useState<string>();

    const handleSignUp = () => {
      setSignUpError(null); //reset errors
      if (!inviteInputValue) {
        setSignUpError("Invite code is required");
        return;
      }
      if (emailInputValue && passwordInputValue) {
        if (confirmInputValue === passwordInputValue) {
          rootStore?.firebase?.db
            .collection("earlyAccessEmails")
            .doc(inviteInputValue)
            .get()
            .then((inviteSnapshot) => {
              if (inviteSnapshot) {
                rootStore?.appUserStore
                  ?.signUpWihEmailPassword(emailInputValue, passwordInputValue)
                  ?.then((authUser: firebase.auth.UserCredential) => {
                    if (authUser && authUser.user) {
                      // Account Created
                      console.log("Account Created");
                    } else {
                      setSignUpError("Unexpected sign up error.");
                      return Promise.reject("Unexpected sign up error.");
                    }
                  })
                  .then(() => {
                    history.push("/dashboard");
                  })
                  .catch((error) => {
                    console.log("Sign Up Error", error);
                    setSignUpError(error.message);
                  });
              } else {
                setSignUpError("Invalid invite code");
              }
            })
            .catch(() => {
              setSignUpError("Invalid invite code");
            });
        } else {
          setSignUpError("Password and confirm don't match");
        }
      } else {
        setSignUpError("Email or Password Empty");
      }
    };

    return (
      <FormConatiner>
        <h2>Sign Up</h2>
        <Form layout="vertical">
          {signUpError && (
            <Alert
              message={signUpError}
              type="error"
              style={{ marginBottom: 10 }}
            />
          )}
          <Form.Item label="Invite Code">
            <Input
              placeholder="Invite Code"
              onChange={(e) => setInviteInputValue(e.target.value)}
            />
            <div style={{ paddingTop: 10 }}>
              Build Better is currently invite only,{" "}
              <a href="https://www.buildbetter.app">request invite</a>.
            </div>
          </Form.Item>
          <Divider />

          <Form.Item label="Email Address">
            <Input
              placeholder="Email Address"
              onChange={(e) => setEmailInputValue(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Password">
            <Input
              placeholder="Password"
              type="password"
              onChange={(e) => setPasswordInputValue(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Confirm Password">
            <Input
              placeholder="Confirm Password"
              type="password"
              onChange={(e) => setConfirmInputValue(e.target.value)}
            />
          </Form.Item>

          <Button type="primary" onClick={handleSignUp}>
            Create Account
          </Button>
        </Form>
      </FormConatiner>
    );
  })
);

export default withRouter(SignUpView);
