import { observable } from "mobx";
import { Workflow } from "../types";
import BaseStore from "./BaseStore";

export default class WorkflowStore extends BaseStore {
  @observable workflows: Workflow[] = [];
  collection = "workflows";

  hydrate() {
    this.whereConditions = [
      [
        "companyId",
        "in",
        this.rootStore.companyStore.companies.map((company) => company.id),
      ],
    ];
    this.fetch((workflowsToHydrate: Workflow[]) => {
      this.logger("hydrate:fetch:callback", workflowsToHydrate.length);
      this.setWorkflows(workflowsToHydrate);
    });
  }

  setWorkflows(workflows: Workflow[]) {
    this.workflows = workflows;
  }

  newWorkflow(): Promise<Workflow> {
    const me = this.rootStore.appUserStore.appUser;
    const firstCompany = this.rootStore.companyStore.companies[0];
    return this.create({
      title: "Untitled Workflow",
      companyId: firstCompany.id,
      participants: [],
      nodes: [],
      runLog: [],
      createdBy: me?.id,
    });
  }

  updateWorkflow(workflows: Workflow) {
    return this.update(workflows);
  }
}
