import * as React from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Divider, Empty, Button, message } from "antd";

import { Survey } from "../types";
import RootStore from "../stores/rootStore";
import { SurveyIcon, CreateIcon } from "../components/icons";

type SurveysViewProps = {
  rootStore?: RootStore;
};

const SurveysView: React.FunctionComponent<SurveysViewProps> = inject(
  "rootStore"
)(
  observer(({ rootStore }) => {
    const companies = rootStore!.companyStore.companies;
    const handleCreateSurvey = () => {
      if (companies.length > 0) {
        rootStore?.surveyStore.newSurvey().then(() => {
          message.success("Survey Created");
          //Logging Event: New Survey Created
          rootStore?.analytics?.logEvent("New Survey", {});
        });
      }
    };

    return (
      <>
        <UIHeader>
          <UITitle>
            <SurveyIcon /> <h2>Surveys</h2>
          </UITitle>
          <UIActions>
            <UICreateAction onClick={handleCreateSurvey}>
              <CreateIcon />
            </UICreateAction>
          </UIActions>
        </UIHeader>
        <Divider />
        {rootStore?.surveyStore.surveys.map((survey: Survey) => {
          return (
            <Link key={`survey_${survey.id}`} to={"/surveys/" + survey.id}>
              <SurveyRow>
                <SurveyTitle>{survey.title}</SurveyTitle>
              </SurveyRow>
            </Link>
          );
        })}
        {rootStore?.surveyStore.surveys.length === 0 ? (
          <Empty image={<SurveyIcon />} description={<span>No Surveys</span>}>
            <Button onClick={handleCreateSurvey} type="primary">
              Create Survey
            </Button>
          </Empty>
        ) : null}
      </>
    );
  })
);

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;
const UIActions = styled.div`
  display: flex;
  align-items: center;
`;
const UICreateAction = styled.div`
  cursor: pointer;
  transition: transform 250ms ease-in-out;
  transform-origin: center center;
  rect {
    fill: #69f0ae;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const SurveyRow = styled.div`
  width: 100%;
  padding: 20px;

  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 10px;
`;

const SurveyTitle = styled.h3`
  margin: 0;
`;

export default SurveysView;
