import { observable } from "mobx";
import { CompanyUser } from "../types";
import BaseStore from "./BaseStore";

export default class CompanyUserStore extends BaseStore {
  @observable companyUsers: CompanyUser[] = [];
  collection = "companyUsers";

  hydrate() {
    this.whereConditions = [
      [
        "companyId",
        "in",
        this.rootStore.companyStore.companies.map((company) => company.id),
      ],
    ];
    this.fetch((companyUsersToHydrate: CompanyUser[]) => {
      this.logger("hydrate:fetch:callback", companyUsersToHydrate.length);
      this.setCompanyUsers(companyUsersToHydrate);
    });
  }

  setCompanyUsers(companyUsers: CompanyUser[]) {
    this.companyUsers = companyUsers;
  }

  updateCompanyUser(companyUser: CompanyUser) {
    return this.update(companyUser);
  }
}
