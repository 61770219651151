import RootStore from "../stores/rootStore";

class AnalyticService {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    // Set user props? not sure if this is needed
    // https://firebase.google.com/docs/analytics/user-properties
    // this.rootStore.firebase.analytics.setUserProperties({});
  }

  logEvent(eventName: string, additionalProps: {}) {
    this.rootStore.firebase?.analytics.logEvent(eventName, additionalProps);
  }

  // rootStore.analytics.logEvent('User logout', {isPro: true});
}

export default AnalyticService;
