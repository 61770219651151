import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { Timeline, Card, Divider, Avatar, List } from "antd";
import moment from "moment";
import RootStore from "../stores/rootStore";
import CardContainer from "../components/cardContainer";
import { CompanyUserAction } from "../types";
import { DashboardIcon } from "../components/icons";
import {
  BranchesOutlined,
  SendOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

type DashboardViewProps = {
  history: History;
  rootStore?: RootStore;
};

const DashboardView: React.FunctionComponent<
  DashboardViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ history, rootStore }) => {
    const companyUserActions = rootStore?.companyUserActionsStore.companyUserActions.sort(
      (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
    );

    return (
      <>
        <UIHeader>
          <UITitle>
            <DashboardIcon /> <h2>Dashboard</h2>
          </UITitle>
          <UIActions></UIActions>
        </UIHeader>
        <Divider />
        <CardContainer>
          <>
            <h2>Recent Activity</h2>
            {companyUserActions ? (
              <Timeline mode="alternate">
                {companyUserActions.map((action: CompanyUserAction) => {
                  const user = rootStore?.companyUsersStore.companyUsers.find(
                    (user) => user.id === action.companyUserId
                  );
                  let description: string | React.ReactElement = action.action;
                  let icon = null;

                  if (action.action === "SEND_EMAIL") {
                    const email = rootStore?.emailStore.emails.find(
                      (e) => e.id === action.details?.email
                    );
                    icon = <SendOutlined />;
                    description = "Sent email " + email?.title;
                  }

                  if (action.action === "SUBMIT_SURVEY") {
                    const survey = rootStore?.surveyStore.surveys.find(
                      (s: any) => s.id === action.details?.survey
                    );
                    icon = <SolutionOutlined />;
                    description = (
                      <div>
                        Submitted survey{" "}
                        <a href={"/surveys/" + survey?.id}>{survey?.title}</a>
                      </div>
                    );
                  }

                  if (action.action === "RUN_WORKFLOW") {
                    const workflow = rootStore?.workflowStore.workflows.find(
                      (w) => w.id === action.details?.workflow
                    );
                    icon = <BranchesOutlined />;
                    description = (
                      <div>
                        Ran workflow{" "}
                        <a href={"/workflows/" + workflow?.id}>
                          {workflow?.title}
                        </a>
                      </div>
                    );
                  }

                  if (user) {
                    return (
                      <Timeline.Item
                        label={moment(action.createdAt).fromNow()}
                        dot={icon}
                      >
                        <Card>
                          <List.Item.Meta
                            avatar={
                              <Avatar size="small">
                                {user?.email.charAt(0).toUpperCase()}
                              </Avatar>
                            }
                            title={user?.name}
                            description={description}
                          />
                        </Card>
                      </Timeline.Item>
                    );
                  }
                  return null;
                })}
              </Timeline>
            ) : null}
          </>
        </CardContainer>
      </>
    );
  })
);

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;
const UIActions = styled.div`
  display: flex;
  align-items: center;
`;

export default withRouter(DashboardView);
