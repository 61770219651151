import styled from "styled-components";

const CardConatinerView = styled.section`
  width: 100%;
  padding: 20px;

  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

export default CardConatinerView;
