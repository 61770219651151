import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createGlobalStyle } from "styled-components";
import { Provider } from "mobx-react";
import "mobx-react-lite/batchingForReactDom";
import App from "./App";
import RootStore from "./stores/rootStore";
import "./App.css";

Sentry.init({
  dsn:
    "https://806dcc6950ef48bd87ab0a2044804bce@o453035.ingest.sentry.io/5441333",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

const rootStore = new RootStore();

const GlobalStyle = createGlobalStyle`
  html {
    --white: #fff;
    --brand-yellow: #ebd408;
    --dark-blue-gray: #141A1F;
    --dark-blue-gray-lighter: #1C242B;
    --light-blue-gray: #8FA5B7;
    --light-blue-gray-lighter: #D1D9E1;
    --light-light-shade-of-dark-blue: #F4F3F6;
  }
  body {
    background-color: var(--light-light-shade-of-dark-blue);
    font-family: 'Heebo', sans-serif;
    font-weight: 400;
  }
  body, #root, .layout {
    min-height: 100%;
  }
`;

ReactDOM.render(
  // <React.StrictMode>
  <Provider rootStore={rootStore}>
    <App />
    <GlobalStyle />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
