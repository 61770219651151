import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { Workflow } from "../types";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import { capitalize, uniq } from "lodash";
import { PageHeader, Divider, Button, Table, Result, message } from "antd";
import CardConatiner from "../components/cardContainer";

type RunWorkflowViewProps = RouteComponentProps & {
  history: History;
  rootStore?: RootStore;
  match: {
    params: {
      workflowId: string;
    };
  };
};

type RunWorkflowViewState = {
  flow?: Workflow;
  loading: boolean;
  selectedRows: string[];
};

@inject("rootStore")
@observer
class RunWorkflowView extends React.Component<
  RunWorkflowViewProps,
  RunWorkflowViewState
> {
  rowSelection: any;
  constructor(props: RunWorkflowViewProps) {
    super(props);
    this.state = {
      loading: true,
      selectedRows: [],
    };

    this.rowSelection = {
      onChange: (selectedRowKeys: string[]) => {
        this.setState({ selectedRows: selectedRowKeys });
      },
    };
  }

  runFlow = (): void => {
    const { rootStore } = this.props;
    const workflow = this.getFlow();
    const hideMessage = message.loading("Running Workflow..", 0);

    console.log(
      "Running flow for...",
      this.rowSelection,
      this.state.selectedRows
    );

    if (workflow) {
      rootStore?.firebase?.cloudFns
        .httpsCallable("runWorkflow")({
          workflowId: workflow.id,
          users: this.state.selectedRows,
        })
        .then((result) => {
          console.log(result);
          if (result && result.data && result.data.error) {
            message.error("Error running workflow: " + result.data.error);
          } else {
            message.success("Workflow successfully completed");
            this.props.history.push("/workflows/" + workflow.id);
          }
          hideMessage();
        });
    }
  };

  getFlow = (): Workflow | undefined => {
    const {
      match: {
        params: { workflowId },
      },
    } = this.props;
    if (this.state.flow) return this.state.flow;
    return this.props.rootStore?.workflowStore.workflows.find(
      (flow: any) => flow.id === workflowId
    );
  };

  render() {
    const { rootStore } = this.props;
    const workflow = this.getFlow();

    let columns: string[] = [];
    rootStore?.companyUsersStore.companyUsers.forEach((user) => {
      columns = [...columns, ...Object.keys(user)];
    });

    columns = uniq(columns);

    // Move Name and email up front, remove id
    columns.splice(columns.indexOf("email"), 1);
    columns.unshift("email");
    columns.splice(columns.indexOf("name"), 1);
    columns.unshift("name");
    columns.splice(columns.indexOf("id"), 1);

    const formatedColumns = columns.map((column: string) => {
      return {
        title: capitalize(column),
        dataIndex: column,
        sorter: (a: any, b: any) => a[column].length - b[column].length,
        render: (value: string) => {
          return value;
        },
      };
    });

    formatedColumns.push({
      title: "Ran on User",
      dataIndex: "ran_on_user",
      sorter: (a: any, b: any) => a.length - b.length,
      render: (value) => {
        return value ? "Yes" : "No";
      },
    });

    return (
      <CardConatiner>
        <PageHeader
          onBack={() => (window.location.href = "/workflows/" + workflow?.id)}
          title={"Run Flow"}
          subTitle={" "}
        />
        <Divider />
        {!workflow?.enabled ? (
          <Result
            status="warning"
            title="This workflow is currently disabled"
          />
        ) : (
          <>
            <Table
              rowSelection={{
                type: "checkbox",
                ...this.rowSelection,
              }}
              columns={formatedColumns}
              dataSource={rootStore?.companyUsersStore.companyUsers.map(
                (user) => ({
                  ...user,
                  key: user.id,
                  ran_on_user: Boolean(
                    workflow.runLog?.find((log) => user.id === log.userId)
                  ),
                })
              )}
            />

            <Button type="primary" size="large" onClick={this.runFlow}>
              Run Workflow for Selected Users
            </Button>
          </>
        )}
      </CardConatiner>
    );
  }
}

export default withRouter(RunWorkflowView);
