import * as React from "react";
import styled from "styled-components";
import { Button } from "antd";
import {
  PieChartOutlined,
  EditOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import { inject } from "mobx-react";
import RootStore from "../../../stores/rootStore";
import { Survey } from "../../../types";

export const CanvasSurveyNode = inject("rootStore")(
  ({
    rootStore,
    attachPropertyToNode,
    node,
  }: {
    rootStore?: RootStore;
    attachPropertyToNode: (id: number, properties: {}) => void;
    node: { id: number; properties: { surveyId: string } };
  }) => {
    console.log("CanvasEmailNode", rootStore);
    // If no email show a create button, else show a edit button.
    // After creating email we need to attach the email ID to the node via properties.nodeId
    return (
      <UIContainer>
        <UIFlowNodeTitleBar>
          <PieChartOutlined
            style={{ fontSize: 24, color: "#888", marginRight: 10 }}
          />
          <h3>Survey</h3>
        </UIFlowNodeTitleBar>
        <UIFlowNodeContent>
          {!node.properties ||
          (node.properties && !node.properties.surveyId) ? (
            <Button
              type="primary"
              onMouseDown={(e) => {
                console.log("onMouseUp");
                e.preventDefault();
                e.stopPropagation();
                rootStore?.surveyStore.newSurvey().then((survey: Survey) => {
                  rootStore?.setUIEditorSurveyId(survey.id);
                  attachPropertyToNode(node.id, { surveyId: survey.id });
                });
              }}
            >
              <DiffOutlined /> Create
            </Button>
          ) : null}
        </UIFlowNodeContent>
        {node.properties && node.properties.surveyId && (
          <UIFlowNodeFooterBar>
            <Button
              onMouseDown={(e) => {
                console.log("onMouseUp");
                e.preventDefault();
                e.stopPropagation();
                rootStore?.setUIEditorSurveyId(node.properties.surveyId);
              }}
            >
              <EditOutlined /> Edit Survey
            </Button>
          </UIFlowNodeFooterBar>
        )}
      </UIContainer>
    );
  }
);

export const CanvasSurveySidebarItem = () => {
  return (
    <UISideBarConatiner>
      <PieChartOutlined
        style={{ marginRight: 10, fontSize: 24, marginTop: 2 }}
      />
      <UISideBarInfoConatiner>
        <h3>Survey</h3>
        <p style={{ color: "#888" }}>
          Build out a survey to be sent to inputted users.
        </p>
      </UISideBarInfoConatiner>
    </UISideBarConatiner>
  );
};

const UIContainer = styled.div``;

const UISideBarConatiner = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  border: #eee 1px solid;
  border-radius: 5px;
  width: 200px;
  align-items: flex-start;
`;

const UISideBarInfoConatiner = styled.div`
  width: 250px;
`;

const UIFlowNodeTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: #eee 1px solid;
  padding: 10px;
  h3 {
    margin: 0;
  }
`;

const UIFlowNodeContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const UIFlowNodeFooterBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: #eee 1px solid;
  padding: 10px;
`;
