const tabCSS = "font-weight:bold; padding: 3px 6px;";
const greenTabCSS = tabCSS + "color:white;background-color:#2ebf46;";
const tealTabCSS = tabCSS + "color:white;background-color:#0f9bff;";

export default (namespace: string) => {
  if (process.env.REACT_APP_DEBUG) {
    console.log("Enabling debug in namespace:", process.env.REACT_APP_DEBUG);
    return (
      textOrMethod: string | number | {},
      text?: string | number | {}
    ) => {
      if (textOrMethod && text) {
        console.log(
          "%c" + namespace + "%c" + textOrMethod,
          greenTabCSS,
          tealTabCSS,
          text
        );
      } else {
        console.log("%c" + namespace, greenTabCSS, textOrMethod);
      }
    };
  }
  return () => {};
};
