import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Table,
  PageHeader,
  Tabs,
  Switch,
  Button,
} from "antd";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { History } from "history";
import { AppUser } from "../types";
import RootStore from "../stores/rootStore";
import styled from "styled-components";
import PageLoadingView from "../components/pageLoading";
import moment from "moment";
const { TabPane } = Tabs;

type AdminViewProps = {
  rootStore?: RootStore;
  history: History;
};

const Container = styled.section``;

const AdminView: React.FunctionComponent<AdminViewProps> = inject("rootStore")(
  ({ rootStore, history }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [requests, setRequests] = React.useState<{ email: string }[]>([]);
    const [bbUsers, setBBUsers] = React.useState<AppUser[]>([]);

    React.useEffect(() => {
      rootStore?.firebase?.db
        .collection("earlyAccessEmails")
        .onSnapshot((querySnapshot) => {
          let results: { email: string }[] = [];
          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            results.push({ ...docData, id: doc.id } as {
              email: string;
              id: string;
            });
          });
          setRequests(results);
          setIsLoading(false);
        });

      rootStore?.firebase?.db
        .collection("users")
        .onSnapshot((querySnapshot) => {
          let results: AppUser[] = [];
          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            results.push({ ...docData, id: doc.id } as AppUser);
          });
          setBBUsers(results);
          setIsLoading(false);
        });
    }, [rootStore]);

    const updateEnabledAppUser = (appUserId: string, enabled: boolean) => {
      const userRef = rootStore?.firebase?.db
        .collection("users")
        .doc(appUserId);
      userRef?.update({ enabled });
    };

    const loginAs = (userId: string) => {
      rootStore?.firebase?.cloudFns
        .httpsCallable("loginAsUser")({ userId })
        .then((result: { data: { success: boolean; token: string } }) => {
          if (result.data.success) {
            rootStore?.firebase?.auth.signInWithCustomToken(result.data.token);
          }
        });
    };

    if (isLoading) {
      return <PageLoadingView />;
    }

    const signUpsToday = requests.filter((req: any) =>
      moment(req.createdAt).isAfter(moment().startOf("day"))
    ).length;
    const signUpsWeek = requests.filter((req: any) =>
      moment(req.createdAt).isAfter(moment().startOf("week"))
    ).length;
    const signUpsMonth = requests.filter((req: any) =>
      moment(req.createdAt).isAfter(moment().startOf("month"))
    ).length;

    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (email: string) => {
          return <a href={`mailto:${email}`}>{email}</a>;
        },
        sorter: (a: any, b: any) => {
          var aEmail = a.email.toUpperCase();
          var bEmail = b.email.toUpperCase();
          if (aEmail < bEmail) {
            return -1;
          }
          if (aEmail > bEmail) {
            return 1;
          }

          return 0;
        },
      },
      {
        title: "Referal",
        dataIndex: "referal",
        key: "referal",
        sorter: (a: any, b: any) =>
          (a.referal ? a.referal.length : 0) -
          (b.referal ? b.referal.length : 0),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a: any, b: any) =>
          moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        render: (datestamp: string) => {
          return datestamp
            ? moment(datestamp).format("MMMM Do YYYY, h:mm:ss a")
            : "";
        },
      },
      {
        title: "Invite Code",
        dataIndex: "id",
        key: "id",
      },
    ];

    const usersSignUpsToday = bbUsers.filter((req: any) =>
      moment(req.createdAt).isAfter(moment().startOf("day"))
    ).length;
    const usersSignUpsWeek = bbUsers.filter((req: any) =>
      moment(req.createdAt).isAfter(moment().startOf("week"))
    ).length;
    const usersSignUpsMonth = bbUsers.filter((req: any) =>
      moment(req.createdAt).isAfter(moment().startOf("month"))
    ).length;

    const usersColumns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        width: 225,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (email: string) => {
          return <a href={`mailto:${email}`}>{email}</a>;
        },
        sorter: (a: any, b: any) => {
          var aEmail = a.email.toUpperCase();
          var bEmail = b.email.toUpperCase();
          if (aEmail < bEmail) {
            return -1;
          }
          if (aEmail > bEmail) {
            return 1;
          }

          return 0;
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a: any, b: any) =>
          (a.referal ? a.referal.length : 0) -
          (b.referal ? b.referal.length : 0),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a: any, b: any) =>
          moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        render: (datestamp: string) => {
          return datestamp
            ? moment(datestamp).format("MMMM Do YYYY, h:mm:ss a")
            : "";
        },
      },
      {
        title: "Enabled",
        dataIndex: "enabled",
        key: "enabled",
        width: 200,
        render: (email: string, record: AppUser) => {
          return (
            <>
              <Switch
                checked={record.enabled}
                onChange={(checked: boolean) =>
                  updateEnabledAppUser(record.id, checked)
                }
              />
              <Button onClick={() => loginAs(record.id)} type="link">
                Login As User
              </Button>
            </>
          );
        },
      },
    ];

    return (
      <section>
        <PageHeader onBack={() => history.push("/dashboard")} title="Admin" />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Users" key="1">
            <Container>
              <Row gutter={16}>
                <Col span={6}>
                  <Statistic title="Sign Ups Today" value={usersSignUpsToday} />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Sign Ups This Week"
                    value={usersSignUpsWeek}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Sign Ups This Month"
                    value={usersSignUpsMonth}
                  />
                </Col>
                <Col span={6}>
                  <Statistic title="Sign Ups All Time" value={bbUsers.length} />
                </Col>
              </Row>
              <Divider />
              <Table
                pagination={false}
                dataSource={bbUsers}
                columns={usersColumns}
              />
            </Container>
          </TabPane>
          <TabPane tab="Early Access Requests" key="2">
            <Container>
              <Row gutter={16}>
                <Col span={6}>
                  <Statistic title="Requests Today" value={signUpsToday} />
                </Col>
                <Col span={6}>
                  <Statistic title="Requests This Week" value={signUpsWeek} />
                </Col>
                <Col span={6}>
                  <Statistic title="Requests This Month" value={signUpsMonth} />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Requests All Time"
                    value={requests.length}
                  />
                </Col>
              </Row>
              <Divider />
              <Table
                pagination={false}
                dataSource={requests}
                columns={columns}
              />
            </Container>
          </TabPane>
        </Tabs>
      </section>
    );
  }
);

export default withRouter(AdminView);
