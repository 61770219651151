import * as React from "react";
import styled from "styled-components";
import { EmailPart } from "../../types";
import { Input } from "antd";
const { TextArea } = Input;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EmailPartRendered = styled.div`
  width: 100%;
  padding: 5px;
  font-size: 12pt;
  line-height: 18pt;
  margin-bottom: 10px;
  font-weight: 400;
  color: rgba(39, 35, 67, 0.75);
`;

const EmailPartTextarea = styled(TextArea)`
  width: 100%;
  background-color: #fff;
  border: #eee 1px solid;
  padding: 5px;
  font-size: 12pt;
  line-height: 18pt;
  margin-bottom: 10px;
  border-radius: 5px;
  font-weight: 400;
  color: rgba(39, 35, 67, 0.75);
`;

const EmailPartParagraphInputQuestion = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  font-size: 14pt;
  font-weight: 700;
  padding: 2px 5px;
  margin-bottom: 0px;
  border-bottom: transparent 2px dashed;
  color: rgba(39, 35, 67, 0.75);
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

const EmailPartParagraphInputSubtitle = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  font-size: 12pt;
  font-weight: 400;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: transparent 2px dashed;
  color: #888;
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

const FakeTextArea = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  color: #ccc;
  height: 80px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

type UserInputParagraphViewProps = {
  part: EmailPart;
  update?: (part: EmailPart) => void;
  onUpdate?: (key: string, value: any) => void;
  value?: string;
};

const UserInputParagraphView: React.FunctionComponent<UserInputParagraphViewProps> = ({
  part,
  update,
}) => {
  return (
    <Container>
      <EmailPartParagraphInputQuestion
        placeholder={"Question"}
        defaultValue={part.value.title}
        onBlur={(e) =>
          update &&
          update({ ...part, value: { ...part.value, title: e.target.value } })
        }
      />
      <EmailPartParagraphInputSubtitle
        placeholder={"Description"}
        defaultValue={part.value.subTitle}
        onBlur={(e) =>
          update &&
          update({
            ...part,
            value: { ...part.value, subTitle: e.target.value },
          })
        }
      />
      <FakeTextArea>User Paragraph Input</FakeTextArea>
    </Container>
  );
};

const UserInputParagraphRenderedView: React.FunctionComponent<UserInputParagraphViewProps> = ({
  part,
  onUpdate,
  value,
}) => {
  return (
    <EmailPartRendered>
      <h3 className="question-title">{part.value.title}</h3>
      {part.value.subTitle && (
        <p className="question-subtitle">{part.value.subTitle}</p>
      )}
      <EmailPartTextarea
        autoSize={{ minRows: 3 }}
        placeholder={""}
        defaultValue={value}
        onChange={(e) => {
          if (onUpdate) {
            onUpdate(part.id, {
              type: "UI_PARAPGRAPH",
              key: part.value.title,
              value: e.target.value,
            });
          }
        }}
      />
    </EmailPartRendered>
  );
};

export { UserInputParagraphRenderedView };

export default UserInputParagraphView;
