import * as React from "react";
import styled from "styled-components";
import { EmailPart } from "../../types";
import { Button, Radio } from "antd";

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  border: none;
  margin-bottom: 10px;
  padding-bottom: 5px;
`;

const EmailPartTitle = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  font-size: 14pt;
  font-weight: 700;
  padding: 2px 5px;
  margin-bottom: 0px;
  border-bottom: transparent 2px dashed;
  color: rgba(39, 35, 67, 0.75);
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

const EmailPartParagraphInputSubtitle = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  font-size: 12pt;
  font-weight: 400;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: transparent 2px dashed;
  color: #888;
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

const AnswerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 5px;
`;

const AnswerInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 10pt;
  padding: 5px;
  margin-left: 5px;
  margin-bottom: 0;
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

type SingleChoiceQuestionViewProps = {
  part: EmailPart;
  update?: (part: EmailPart) => void;
  onUpdate?: (key: string, value: any) => void;
  value?: string;
};

const SingleChoiceQuestionView: React.FunctionComponent<SingleChoiceQuestionViewProps> = ({
  part,
  update,
}) => {
  const answers = part.value.answers ? part.value.answers : [""];
  console.log("answers", answers);
  return (
    <Container>
      <EmailPartTitle
        placeholder={"Question"}
        defaultValue={part.value.title}
        onBlur={(e) =>
          update &&
          update({ ...part, value: { ...part.value, title: e.target.value } })
        }
      />
      <EmailPartParagraphInputSubtitle
        placeholder={"Description"}
        defaultValue={part.value.subTitle}
        onBlur={(e) =>
          update &&
          update({
            ...part,
            value: { ...part.value, subTitle: e.target.value },
          })
        }
      />
      <Radio.Group>
        {answers.map((answer, i) => {
          return (
            <AnswerRow>
              <Radio />
              <AnswerInput
                defaultValue={answer || `Answer ${i + 1}`}
                onBlur={(e) => {
                  let newAnswers = answers;
                  newAnswers[i] = e.target.value;
                  update &&
                    update({
                      ...part,
                      value: {
                        ...part.value,
                        answers: newAnswers,
                      },
                    });
                }}
              />
            </AnswerRow>
          );
        })}
      </Radio.Group>
      <Button
        onMouseUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
          console.log({
            ...part,
            value: {
              ...part.value,
              answers: [...answers, ""],
            },
          });

          update &&
            update({
              ...part,
              value: {
                ...part.value,
                answers: [...answers, ""],
              },
            });
        }}
      >
        Add Answer
      </Button>
    </Container>
  );
};

const SingleChoiceQuestionRenderedView: React.FunctionComponent<SingleChoiceQuestionViewProps> = ({
  part,
  onUpdate,
  value,
}) => {
  let answers = [""];
  if (part.value.answers) {
    answers = part.value.answers;
  }

  return (
    <Container style={{ backgroundColor: "transparent" }}>
      <h3 className="question-title">{part.value.title}</h3>
      {part.value.subTitle && (
        <p className="question-subtitle">{part.value.subTitle}</p>
      )}
      <Radio.Group
        defaultValue={value}
        onChange={(e) => {
          if (onUpdate) {
            onUpdate(part.id, {
              type: "UI_SC_QUESTION",
              key: part.value.title,
              value: e.target.value,
            });
          }
        }}
      >
        {answers.map((answer, i) => {
          return (
            <AnswerRow key={`${part.id}_${i}`}>
              <Radio value={answer}>{answer}</Radio>
            </AnswerRow>
          );
        })}
      </Radio.Group>
    </Container>
  );
};

export { SingleChoiceQuestionRenderedView };

export default SingleChoiceQuestionView;
