import * as React from "react";
import styled from "styled-components";
import { EmailPart } from "../../types";
import { Input } from "antd";
const { TextArea } = Input;

const EmailPartRendered = styled.p`
  width: 100%;
  background-color: #fff;
  padding: 5px;
  font-size: 12pt;
  line-height: 18pt;
  margin-bottom: 10px;
  font-weight: 400;
  color: rgba(39, 35, 67, 0.75);
`;

const EmailPartTextarea = styled(TextArea)`
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 5px;
  font-size: 12pt;
  line-height: 18pt;
  margin-bottom: 10px;
  font-weight: 400;
  color: rgba(39, 35, 67, 0.75);
  &:active,
  &:hover {
    border-bottom: #eee 2px dashed;
  }
`;

type ParagraphViewProps = {
  part: EmailPart;
  update?: (part: EmailPart) => void;
};

const ParagraphView: React.FunctionComponent<ParagraphViewProps> = ({
  part,
  update,
}) => {
  return (
    <EmailPartTextarea
      autoSize={true}
      placeholder={"Paragraph"}
      defaultValue={part.value.body}
      onBlur={(e) =>
        update && update({ ...part, value: { body: e.target.value } })
      }
    />
  );
};

const ParagraphRenderedView: React.FunctionComponent<ParagraphViewProps> = ({
  part,
}) => {
  return <EmailPartRendered>{part.value.body}</EmailPartRendered>;
};

export { ParagraphRenderedView };

export default ParagraphView;
