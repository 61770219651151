import { observable, toJS } from "mobx";
import firebaseConfig from "../firebase-config.json"; // move this to env vars
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/storage";
import {
  Company,
  CompanyUser,
  Flow,
  Project,
  EmailCampaign,
  User,
} from "../types";
import { History } from "history";
import Logger from "../logger";

import AppUserStore from "./appUserStore";
import SurveyStore from "./surveyStore";
import SurveyResultStore from "./surveyResultStore";
import EmailStore from "./emailStore";
import WorkflowStore from "./workflowStore";
import CompanyStore from "./companyStore";
import CompanyUserStore from "./companyUsers";
import CompanyUserActionStore from "./companyUserAction";
import AnalyticService from "../services/AnalyticService";

const logger = Logger("RootStore");

interface FirebaseOnRootStoreInterface {
  auth: app.auth.Auth;
  db: app.firestore.Firestore;
  cloudFns: app.functions.Functions;
  analytics: app.analytics.Analytics;
  storage: app.storage.Storage;
}

export default class RootStore {
  // Stores
  appUserStore: AppUserStore;
  surveyStore: SurveyStore;
  surveyResultStore: SurveyResultStore;
  emailStore: EmailStore;
  workflowStore: WorkflowStore;
  companyStore: CompanyStore;
  companyUsersStore: CompanyUserStore;
  companyUserActionsStore: CompanyUserActionStore;

  // Services
  firebase?: FirebaseOnRootStoreInterface;
  analytics?: AnalyticService;

  @observable UIEditorEmailId: boolean | string = false;
  @observable UIEditorSurveyId: boolean | string = false;

  // Root loading state, loading in stores, firebase etc...
  @observable initializing: boolean = true;

  // Require Company Create
  @observable requireCompanyCreate: boolean = false;

  constructor() {
    logger("constructor");

    this.appUserStore = new AppUserStore(this);
    this.surveyStore = new SurveyStore(this);
    this.surveyResultStore = new SurveyResultStore(this);
    this.emailStore = new EmailStore(this);
    this.workflowStore = new WorkflowStore(this);
    this.companyStore = new CompanyStore(this);
    this.companyUsersStore = new CompanyUserStore(this);
    this.companyUserActionsStore = new CompanyUserActionStore(this);

    // Expose to window object for debugging
    (window as any).rootStore = this;

    // Setup Firebase instance
    if (!app.apps.length) {
      if (
        //@ts-ignore: We are setting this in env's
        process.env.NODE_ENV === "staging" ||
        process.env.NODE_ENV === "production"
      ) {
        fetch("/__/firebase/init.json").then(async (response) => {
          app.initializeApp(await response.json());
          this.setupFirebaseServices();
        });
      } else {
        logger(
          "constructor:firebase-setup",
          "Initializing with default config from firebase-config.json " +
            process.env.NODE_ENV
        );
        app.initializeApp(firebaseConfig);
        this.setupFirebaseServices();
      }
    }
  }

  setupFirebaseServices() {
    this.firebase = {
      auth: app.auth(),
      db: app.firestore(),
      cloudFns: app.functions(),
      analytics: app.analytics(),
      storage: app.storage(),
    };

    // Point to localhost if in development
    if (["development", "test"].indexOf(process.env.NODE_ENV) > -1) {
      console.log("Pointing Firebase to Local Firebase Emulator");
      this.firebase.cloudFns.useFunctionsEmulator("http://localhost:5001");
      this.firebase.db.settings({
        host: "localhost:8080",
        ssl: false,
      });
    }

    this.analytics = new AnalyticService(this);

    // Listen for auth changes
    this.appUserStore.listenForAuthChanges();
  }

  dump() {
    return toJS(this);
  }

  hydrateStoresWithUserData() {
    this.surveyStore.hydrate();
    this.surveyResultStore.hydrate();
    this.emailStore.hydrate();
    this.workflowStore.hydrate();
    this.companyUsersStore.hydrate();
    this.companyUserActionsStore.hydrate();
  }

  setMe(user: User | null) {
    console.log("RootStore:setMe", user);

    if (user) {
      this.appUserStore.hydrate(user.id);
    }
  }

  setCompanies(companies: Company[]) {
    // this.companies = companies;
    // We got company pull in data...
  }

  setCompanyUsers(companyUsers: CompanyUser[]) {
    // this.companyUsers = companyUsers;
  }

  setFlows(flows: Flow[]) {
    // this.flows = flows;
  }

  setEmailCampaigns(emailCampaigns: EmailCampaign[]) {
    // this.emailCampaigns = emailCampaigns;
  }

  setUIEditorEmailId(id: string | boolean) {
    console.log("RootStore:setUIEditorEmailId", id);
    this.UIEditorEmailId = id;
  }

  setUIEditorSurveyId(id: string | boolean) {
    console.log("RootStore:setUIEditorSurveyId", id);
    this.UIEditorSurveyId = id;
  }

  companyFlows(companyId: string): void {
    // return this.flows.filter((flow: Flow) => flow.companyId === companyId);
  }

  companyProject(projectId: string): Project | undefined {
    let foundProject;
    // this.companies.forEach((company) => {
    //   company.projects.forEach((project) => {
    //     if (project.id === projectId) {
    //       foundProject = project;
    //     }
    //   });
    // });
    return foundProject;
  }

  emailCampaignsForProject(projectId: string): void {
    // return this.emailCampaigns.filter((ec) => ec.projectId === projectId);
  }

  emailCampaign(emailId: string): void {
    // return this.emailCampaigns.find((ec) => ec.id === emailId);
  }

  // Helpers
  isLoggedIn(history: History): void {
    return history.push("/login");
  }
}
