import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { Workflow } from "../types";
import { inject, observer } from "mobx-react";
import RootStore from "../stores/rootStore";
import moment from "moment";
import { Button, Switch, Divider, Timeline, Alert, message } from "antd";
import styled from "styled-components";
import CardConatiner from "../components/cardContainer";
import { ReloadOutlined, LeftOutlined } from "@ant-design/icons";
import { WorkflowIcon } from "../components/icons";

type ViewWorkflowViewProps = RouteComponentProps & {
  history: History;
  rootStore?: RootStore;
  match: {
    params: {
      workflowId: string;
    };
  };
};

type ViewWorkflowViewState = {
  flow?: Workflow;
  loading: boolean;
};

@inject("rootStore")
@observer
class ViewWorkflowView extends React.Component<
  ViewWorkflowViewProps,
  ViewWorkflowViewState
> {
  constructor(props: ViewWorkflowViewProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  runFlow = (): void => {
    const { rootStore } = this.props;
    const workflow = this.getFlow();
    const hideMessage = message.loading("Running Workflow..", 0);

    if (workflow) {
      rootStore?.firebase?.cloudFns
        .httpsCallable("runWorkflow")({
          workflowId: workflow.id,
          userId: rootStore?.appUserStore?.appUser?.id,
        })
        .then((result) => {
          console.log(result);
          if (result && result.data && result.data.error) {
            message.error("Error running workflow: " + result.data.error);
          } else {
            message.success("Workflow successfully completed");
          }
          hideMessage();
        });
    }
  };

  getFlow = (): Workflow | undefined => {
    const {
      match: {
        params: { workflowId },
      },
    } = this.props;
    if (this.state.flow) return this.state.flow;
    return this.props.rootStore?.workflowStore.workflows.find(
      (flow: any) => flow.id === workflowId
    );
  };

  handleWorkflowUpdate = (updatedFlow: Workflow): void => {
    console.log("handleWorkflowUpdate", updatedFlow);
    const existingFlow = this.state.flow ? this.state.flow : this.getFlow();
    this.setState({ flow: { ...existingFlow, ...updatedFlow } }, console.log);
  };

  render() {
    const { rootStore, history } = this.props;
    const workflow = this.getFlow();
    return (
      <CardConatiner>
        <UIHeader>
          <UITitle>
            <Button
              type="text"
              shape="circle"
              icon={<LeftOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => history.goBack()}
            />
            <SectionIcon>
              <WorkflowIcon />
            </SectionIcon>
            <h2>{workflow?.title}</h2>
          </UITitle>
          <UIActions>
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={workflow?.enabled}
              onChange={(enabled: boolean) => {
                rootStore?.workflowStore
                  .updateWorkflow({ ...workflow, enabled } as Workflow)
                  .then(() => {
                    message.success(
                      "Workflow " + (enabled ? "Enabled" : "Disabled")
                    );
                  });
              }}
            />
            <Button
              icon={<ReloadOutlined />}
              onClick={() => history.push("/workflows/run/" + workflow?.id)}
              style={{ marginLeft: 10 }}
            >
              Manually Run Workflow
            </Button>
            <Button
              type="primary"
              onClick={() => history.push("/workflows/edit/" + workflow?.id)}
              style={{ marginLeft: 10 }}
            >
              Edit Workflow
            </Button>
          </UIActions>
        </UIHeader>
        <Divider />
        {workflow?.enabled ? (
          <Alert
            message="This workflow is currently enabled"
            type="success"
            showIcon
          />
        ) : (
          <Alert
            message="This workflow is currently disabled"
            type="info"
            showIcon
          />
        )}
        {workflow &&
          workflow.runOn &&
          workflow.runOn.indexOf("user_created") > -1 && (
            <Alert
              style={{ marginTop: 10 }}
              message="This workflow runs automatically when a new user is created"
              type="info"
              showIcon
            />
          )}
        <Divider />

        {workflow && workflow?.runLog && (
          <Timeline>
            {workflow.runLog.map(
              (log: { userId: string; completedAt: string }) => {
                const user = rootStore?.companyUsersStore.companyUsers.find(
                  (user) => user.id === log.userId
                );
                return (
                  <Timeline.Item>
                    Successfully completed at {log.completedAt} for{" "}
                    {user ? `${user.name} (${user.email})` : `Unknown user`}
                  </Timeline.Item>
                );
              }
            )}
            <Timeline.Item>
              Workflow created {moment(workflow.createdAt).toISOString()}
            </Timeline.Item>
          </Timeline>
        )}
      </CardConatiner>
    );
  }
}

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
`;

const SectionIcon = styled.div`
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;

const UIActions = styled.div`
  display: flex;
  align-items: center;
`;

export default withRouter(ViewWorkflowView);
