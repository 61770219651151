import * as React from "react";
import styled from "styled-components";
import { TeamOutlined } from "@ant-design/icons";

export const DataUsersNode = () => {
  return (
    <UIContainer>
      <UIFlowNodeTitleBar>
        <TeamOutlined
          style={{ fontSize: 24, color: "#888", marginRight: 10 }}
        />
        <h3>All Users</h3>
      </UIFlowNodeTitleBar>
    </UIContainer>
  );
};

export const DataUsersSidebarItem = () => {
  return (
    <UISideBarConatiner>
      <TeamOutlined style={{ marginRight: 10, fontSize: 24, marginTop: 2 }} />
      <UISideBarInfoConatiner>
        <h3>All Users</h3>
        <p style={{ color: "#888" }}>
          Pulls in users imported into your BuildBetter company account.
        </p>
      </UISideBarInfoConatiner>
    </UISideBarConatiner>
  );
};

const UIContainer = styled.div`
  padding: 15px;
  min-width: 400px;
`;

const UISideBarConatiner = styled.div`
  padding: 15px;
  display: flex;
  border: #eee 1px solid;
  border-radius: 5px;
  width: 200px;
  align-items: flex-start;
`;

const UIFlowNodeTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: #eee 1px solid;
  padding: 10px;
  h3 {
    margin: 0;
  }
`;

const UISideBarInfoConatiner = styled.div`
  width: 250px;
`;
