import * as React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { MailOutlined, EditOutlined, DiffOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import RootStore from "../../../stores/rootStore";
import { Email } from "../../../types";

export const CanvasEmailNode = inject("rootStore")(
  observer(
    ({
      rootStore,
      attachPropertyToNode,
      node,
    }: {
      rootStore?: RootStore;
      attachPropertyToNode: (id: number, properties: {}) => void;
      node: { id: number; properties: { emailId: string } };
    }) => {
      const email = rootStore?.emailStore.emails.find(
        (email) => email.id === node.properties.emailId
      );
      console.log("CanvasEmailNode", rootStore);
      // If no email show a create button, else show a edit button.
      // After creating email we need to attach the email ID to the node via properties.nodeId
      return (
        <UIContainer>
          <UIFlowNodeContent>
            {!node.properties ||
            (node.properties && !node.properties.emailId) ? (
              <Button
                type="primary"
                onMouseDown={(e) => {
                  console.log("onMouseUp");
                  e.preventDefault();
                  e.stopPropagation();
                  rootStore?.emailStore.newEmail().then((email: Email) => {
                    attachPropertyToNode(node.id, { emailId: email.id });
                    rootStore?.setUIEditorEmailId(email.id);
                  });
                }}
              >
                <DiffOutlined /> Create
              </Button>
            ) : (
              <EmailPreviewContainer>
                <EmailPreview srcDoc={email?.encodedHTML}></EmailPreview>
              </EmailPreviewContainer>
            )}
          </UIFlowNodeContent>
          {node.properties && node.properties.emailId && (
            <UIFlowNodeFooterBar>
              <Button
                onMouseDown={(e) => {
                  console.log("onMouseUp");
                  e.preventDefault();
                  e.stopPropagation();
                  rootStore?.setUIEditorEmailId(node.properties.emailId);
                }}
              >
                <EditOutlined /> Edit Email
              </Button>
            </UIFlowNodeFooterBar>
          )}
        </UIContainer>
      );
    }
  )
);

export const CanvasEmailSidebarItem = () => {
  return (
    <UISideBarConatiner>
      <MailOutlined style={{ marginRight: 10, fontSize: 24, marginTop: 2 }} />
      <UISideBarInfoConatiner>
        <h3>Email</h3>
        <p style={{ color: "#888" }}>
          Build out an email to be sent to inputted users.
        </p>
      </UISideBarInfoConatiner>
    </UISideBarConatiner>
  );
};

const UIContainer = styled.div`
  min-width: 400px;
`;

const UISideBarConatiner = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  border: #eee 1px solid;
  border-radius: 5px;
  width: 200px;
  align-items: flex-start;
`;

const UISideBarInfoConatiner = styled.div`
  width: 250px;
`;

const UIFlowNodeContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UIFlowNodeFooterBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: #eee 1px solid;
  padding-top: 10px;
`;

const EmailPreviewContainer = styled.div`
  height: 410px;
  width: 400px;
`;

const EmailPreview = styled.iframe`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  padding: 10px;
  border: none;
  height: 800px;
  width: 800px;
  transform-origin: top left;
  transform: scale(0.5);
`;
