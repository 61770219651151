import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { inject, observer } from "mobx-react";
import moment from "moment";
import styled from "styled-components";
import RootStore from "../stores/rootStore";
import CardContainer from "../components/cardContainer";
import { Button, Alert, Divider, message } from "antd";

type AccountViewProps = {
  history: History;
  rootStore?: RootStore;
};

const AccountView: React.FunctionComponent<
  AccountViewProps & RouteComponentProps
> = inject("rootStore")(
  observer(({ history, rootStore }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const goToStripePortal = () => {
      setIsLoading(true);
      rootStore?.firebase?.cloudFns
        .httpsCallable("getStripePortalURL")({})
        .then(
          (result: { data: { error: string; session: { url: string } } }) => {
            console.log(result);
            if (result.data.error) {
              message.error(result.data.error);
            } else {
              window.location.href = result.data.session.url;
            }
          }
        );
    };

    return (
      <CardContainer>
        <UIHeader>
          <UITitle>
            <h2>Account</h2>
          </UITitle>
          <UIActions>
            {/* <UICreateAction onClick={handleCreateWorkflow}>
              <CreateIcon />
            </UICreateAction> */}
          </UIActions>
        </UIHeader>
        <Divider />
        <div>
          {rootStore &&
          rootStore?.companyStore.companies &&
          rootStore?.companyStore.companies[0] &&
          rootStore?.companyStore.companies[0].subscription_expiration_date &&
          moment
            .unix(
              parseInt(
                rootStore?.companyStore.companies[0]
                  .subscription_expiration_date
              )
            )
            .isAfter(moment()) ? (
            <Alert
              type="success"
              description={`Expires on ${moment
                .unix(
                  parseInt(
                    rootStore?.companyStore.companies[0]
                      .subscription_expiration_date
                  )
                )
                .toISOString()}`}
              message="Active Subscription"
            />
          ) : (
            <Alert type="error" message="No active Subscription" />
          )}
          <Button
            style={{ marginTop: 10 }}
            onClick={goToStripePortal}
            loading={isLoading}
          >
            Manage Billing
          </Button>
          <Divider />
          {/* <Button onClick={goToStripePortal}>Go to billing portal</Button>
          <Button onClick={goToStripeCheckout}>Go to checkout</Button> */}
          <p>Want to cancel? Contact our support.</p>
          <Button onClick={() => (window as any).Intercom("showMessages")}>
            Contact support
          </Button>
        </div>
      </CardContainer>
    );
  })
);

const UIHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UITitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
  }
  svg {
    height: 30px;
    width: auto;
    margin-right: 15px;
  }
`;
const UIActions = styled.div`
  display: flex;
  align-items: center;
`;

export default withRouter(AccountView);
