import * as React from "react";
import { History } from "history";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Button, message, Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import RootStore from "../stores/rootStore";
import { EmailPart, Survey } from "../types";

import { TitleRenderedView } from "../components/emailParts/Title";
import { SubTitleRenderedView } from "../components/emailParts/SubTitle";
import { ParagraphRenderedView } from "../components/emailParts/Paragraph";
import { ImageRenderedView } from "../components/emailParts/Image";
import { MultipleChoiceQuestionRenderedView } from "../components/emailParts/MultipleChoiceQuestion";
import { SingleChoiceQuestionRenderedView } from "../components/emailParts/SingleChoiceQuestion";
import { UserInputParagraphRenderedView } from "../components/emailParts/UserInputParagraph";
import { UserInputTextRenderedView } from "../components/emailParts/UserInputText";

const EmailConatiner = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

const PoweredByBB = styled.div`
  text-align: center;
  padding-top: 25px;
  color: #888;
  a {
    color: #555;
    font-weight: 800;
  }
`;

const EmailContent = styled.div`
  height: auto;
  min-height: 300px;
  min-width: 700px;
  max-width: 700px;
  padding: 40px;

  @media (max-width: 700px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 20px;
  }

  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), -2px 0px 4px rgba(0, 0, 0, 0.05),
    0px -1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const SurveyButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmailPartMoveHandle = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 3px;
  cursor: move;
`;

const EmailPartContainer = styled.li`
  position: relative;
  list-style: none;
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  &:hover ${EmailPartMoveHandle} {
    display: block;
  }
`;

const RequiredTag = styled.div<{ isMissing?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 5px;
  bottom: -5px;
  color: ${({ isMissing }): string => (isMissing ? "red" : "#888")};
  font-size: 8pt;
`;

const NodeConatiner = styled.div`
  position: relative;
`;

type SurveyViewProps = {
  rootStore?: RootStore;
  history: History;
  match: {
    params: {
      surveyId: string;
      userId: string;
    };
  };
};

const SurveyView: React.FunctionComponent<SurveyViewProps> = inject(
  "rootStore"
)(
  observer(
    ({
      rootStore,
      history,
      match: {
        params: { surveyId, userId },
      },
    }) => {
      document.title = "Survey";
      (window as any).Intercom("shutdown");

      const [currentPage, setCurrentPage] = React.useState<number>(0);
      const [complete, setComplete] = React.useState<boolean>(false);
      const [payloadToSave, setPayloadToSave] = React.useState<any>({});
      const [loading, setLoading] = React.useState(true);
      const [error, setError] = React.useState(false);
      const [survey, setSurvey] = React.useState<Survey | undefined>();
      const [emptyRequiredNodes, setEmptyRequiredNodes] = React.useState<
        EmailPart[]
      >([]);

      // exclude bb_email_address if id ser
      let surveyContentNodes = survey?.content || [];
      if (userId && surveyContentNodes) {
        surveyContentNodes = surveyContentNodes.filter(
          (node) => node.id !== "bb_email_address"
        );
      }

      React.useEffect(() => {
        rootStore?.firebase?.db
          .collection("surveys")
          .doc(surveyId)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot) {
              setLoading(false);
              setError(true);
            } else {
              const survey = querySnapshot.data() as Survey;

              // Make sure if no user it is public
              if (!userId && !survey.isPublic) {
                setLoading(false);
                setError(true);
              } else {
                setSurvey(survey);
                setLoading(false);
                document.title = survey.title;
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
          });
      }, [surveyId, userId, rootStore, setLoading, setError]);

      const handlePayloadToSaveUpdate = (key: string, value: {}) => {
        let payloadToSaveClone: any = payloadToSave;
        payloadToSaveClone[key] = value;
        setPayloadToSave(payloadToSaveClone);
      };

      const checkRequiredFields = (nodes: EmailPart[]): boolean => {
        const requiredNodes = nodes.filter((node) => node.value.required);
        if (requiredNodes && requiredNodes.length > 0) {
          const foundMissingRequiredNode = requiredNodes.filter(
            (requiredNode: EmailPart) => {
              if (!payloadToSave[requiredNode.id]) return true;
              if (
                payloadToSave[requiredNode.id] &&
                !payloadToSave[requiredNode.id].value
              )
                return true;
              if (
                payloadToSave[requiredNode.id] &&
                payloadToSave[requiredNode.id].value &&
                typeof payloadToSave[requiredNode.id].value === "string" &&
                !payloadToSave[requiredNode.id].value.trim()
              )
                return true;
              if (
                payloadToSave[requiredNode.id] &&
                payloadToSave[requiredNode.id].value &&
                payloadToSave[requiredNode.id].value.length === 0
              )
                return true;

              return false;
            }
          );
          if (foundMissingRequiredNode && foundMissingRequiredNode.length > 0) {
            setEmptyRequiredNodes(foundMissingRequiredNode);
            message.error("Missing required fields.");
          }
          return foundMissingRequiredNode.length === 0;
        } else {
          return true;
        }
      };

      const submitSuvery = () => {
        setLoading(true);

        if (survey && !checkRequiredFields(surveyContentNodes)) {
          setLoading(false);
          return;
        }

        if (window.location.search.indexOf("preview=true") > -1) {
          // it's a preview don't save
          setComplete(true);
          setLoading(false);
          return;
        }

        rootStore?.firebase?.cloudFns
          .httpsCallable("saveSurveyResults")({
            api_key: survey?.companyId,
            surveyId: survey?.id,
            userId,
            ...payloadToSave,
          })
          .then(function (result) {
            setComplete(true);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setError(true);
            setLoading(false);
          });
      };

      let currentLoopPage = 0;
      let surveyPages: Array<EmailPart[]> = [];
      if (survey && surveyContentNodes) {
        surveyContentNodes.forEach((node) => {
          if (!surveyPages[currentLoopPage]) {
            surveyPages[currentLoopPage] = [];
          }
          if (node.type !== "PAGE_BREAK") {
            surveyPages[currentLoopPage].push(node);
          } else {
            currentLoopPage = currentLoopPage + 1;
            surveyPages[currentLoopPage] = [];
            surveyPages[currentLoopPage].push(node);
          }
        });
      }

      const currentPageNode = surveyPages[currentPage];

      const goToNextPage = () => {
        if (checkRequiredFields(surveyPages[currentPage])) {
          setCurrentPage(currentPage + 1);
        }
      };
      const goToBackPage = () => {
        setCurrentPage(currentPage - 1);
      };

      return (
        <>
          <form>
            <EmailConatiner>
              <EmailContent className="survey-container">
                {loading ? (
                  <LoadingOutlined style={{ fontSize: 55 }} spin />
                ) : complete ? (
                  <Result
                    status="success"
                    title="Successfully Submitted!"
                    subTitle="Thank you for completing the suvery."
                  />
                ) : error ? (
                  <Result status="warning" title="Survey Not Found" />
                ) : (
                  <>
                    {currentPageNode &&
                      currentPageNode.map((value, i) => {
                        const isInMissingRequired = Boolean(
                          emptyRequiredNodes.find(
                            (mn: EmailPart) => mn.id === value.id
                          )
                        );
                        if (value.type === "title") {
                          return (
                            <TitleRenderedView
                              part={value}
                              key={`part_${value.id}`}
                            />
                          );
                        } else if (value.type === "subtitle") {
                          return (
                            <SubTitleRenderedView
                              part={value}
                              key={`part_${value.id}`}
                            />
                          );
                        } else if (value.type === "paragraph") {
                          return (
                            <ParagraphRenderedView
                              part={value}
                              key={`part_${value.id}`}
                            />
                          );
                        } else if (value.type === "MC_QUESTION") {
                          return (
                            <NodeConatiner key={`part_${value.id}`}>
                              {value.value.required && (
                                <RequiredTag isMissing={isInMissingRequired}>
                                  Required
                                </RequiredTag>
                              )}
                              <MultipleChoiceQuestionRenderedView
                                part={value}
                                onUpdate={handlePayloadToSaveUpdate}
                                value={payloadToSave[value.id]?.value}
                              />
                            </NodeConatiner>
                          );
                        } else if (value.type === "UI_SC_QUESTION") {
                          return (
                            <NodeConatiner key={`part_${value.id}`}>
                              {value.value.required && (
                                <RequiredTag isMissing={isInMissingRequired}>
                                  Required
                                </RequiredTag>
                              )}
                              <SingleChoiceQuestionRenderedView
                                part={value}
                                onUpdate={handlePayloadToSaveUpdate}
                                value={payloadToSave[value.id]?.value}
                              />
                            </NodeConatiner>
                          );
                        } else if (value.type === "UI_PARAGRAPH") {
                          return (
                            <NodeConatiner key={`part_${value.id}`}>
                              {value.value.required && (
                                <RequiredTag isMissing={isInMissingRequired}>
                                  Required
                                </RequiredTag>
                              )}
                              <UserInputParagraphRenderedView
                                part={value}
                                onUpdate={handlePayloadToSaveUpdate}
                                value={payloadToSave[value.id]?.value}
                              />
                            </NodeConatiner>
                          );
                        } else if (value.type === "UI_TEXT") {
                          return (
                            <NodeConatiner key={`part_${value.id}`}>
                              {value.value.required && (
                                <RequiredTag isMissing={isInMissingRequired}>
                                  Required
                                </RequiredTag>
                              )}
                              <UserInputTextRenderedView
                                part={value}
                                onUpdate={handlePayloadToSaveUpdate}
                                value={payloadToSave[value.id]?.value}
                              />
                            </NodeConatiner>
                          );
                        } else if (value.type === "image") {
                          return (
                            <ImageRenderedView
                              key={`part_${value.id}`}
                              part={value}
                            />
                          );
                        } else if (value.type === "cta") {
                          return (
                            <EmailPartContainer key={`part_${value.id}`}>
                              <a href={value.value.href}>{value.value.title}</a>
                            </EmailPartContainer>
                          );
                        } else {
                          return null;
                        }
                      })}
                    <SurveyButtonContainer>
                      {currentPage !== 0 ? (
                        <Button
                          size="large"
                          onClick={goToBackPage}
                          className="button back"
                        >
                          Back
                        </Button>
                      ) : (
                        <span />
                      )}
                      {currentPage === surveyPages.length - 1 ? (
                        <Button
                          type="primary"
                          size="large"
                          onClick={submitSuvery}
                          className="button submit"
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          size="large"
                          onClick={goToNextPage}
                          className="button next"
                        >
                          Next
                        </Button>
                      )}
                    </SurveyButtonContainer>
                  </>
                )}
              </EmailContent>
              <PoweredByBB>
                User Driven feedback powered by{" "}
                <a href="https://www.buildbetter.app/">BuildBetter</a>
              </PoweredByBB>
            </EmailConatiner>
          </form>
          {survey && survey.customCSS && (
            <style type="text/css">{survey.customCSS}</style>
          )}
        </>
      );
    }
  )
);

export default SurveyView;
