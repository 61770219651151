import * as React from "react";
import { Workflow, FlowNode } from "../types";
import { Card, Divider, Popover } from "antd";
import styled from "styled-components";
import {
  DragOutlined,
  DeleteOutlined,
  UserOutlined,
  AuditOutlined,
  FilterOutlined,
  MailOutlined,
  CoffeeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from "react-sortable-hoc";
import { v4 as uuid } from "uuid";

import { NodeInner } from "../components/flow/nodes";

type WorkflowEditorViewProps = {
  workflow?: Workflow;
  updateWorkflow: (flow: Workflow) => void;
};

const WorkflowEditorView: React.FunctionComponent<WorkflowEditorViewProps> = ({
  workflow,
  updateWorkflow,
}) => {
  const handleDeleteNode = (nodeToRemove: FlowNode) => {
    if (workflow) {
      let nodes = workflow.nodes;
      if (nodes) {
        updateWorkflow({
          ...workflow,
          nodes: nodes.filter((node) => node.id !== nodeToRemove.id),
        });
      }
    }
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (workflow) {
      let nodes = workflow.nodes;
      if (nodes) {
        nodes = arrayMove(nodes, oldIndex, newIndex);
        updateWorkflow({ ...workflow, nodes });
      }
    }
  };

  const attachPropertyToNode = (id: string | number, properties: {}) => {
    console.log("attachPropertyToNode", id, properties);
    if (workflow) {
      const workflowClone = workflow;
      if (workflowClone.nodes) {
        const nodeIndex = workflowClone.nodes.findIndex(
          (node) => node.id === id
        );
        console.log("nodeIndex", nodeIndex);
        const nodechart = workflowClone.nodes[nodeIndex];
        console.log("workflowClone.nodes", workflowClone.nodes);
        console.log("nodechart", id, nodechart);

        if (nodechart) {
          workflowClone.nodes[nodeIndex] = {
            ...nodechart,
            properties: { ...(nodechart.properties || {}), ...properties },
          };

          console.log(
            "workflowClone.nodes[id]",
            workflowClone.nodes[nodeIndex]
          );
        }

        updateWorkflow(workflowClone);
      }
    }
  };

  const handleAddNode = (node: FlowNode) => {
    if (workflow) {
      let nodes = workflow.nodes || [];
      if (nodes) {
        nodes = nodes.concat({ ...node, id: uuid() });
        updateWorkflow({ ...workflow, nodes });
      }
    }
  };

  const nodeTitle = (node: FlowNode) => {
    switch (node.type) {
      case "USER":
        return (
          <>
            <UserOutlined
              style={{ fontSize: 24, color: "#0c71ff", marginRight: 10 }}
            />
            <h3>Incoming User</h3>
          </>
        );
      case "EMAIL":
        return (
          <>
            <MailOutlined
              style={{ fontSize: 24, color: "#85009a", marginRight: 10 }}
            />
            <h3>Send Email</h3>
          </>
        );
      case "USER_FILTER":
        return (
          <>
            <FilterOutlined
              style={{ fontSize: 24, color: "#fe9b0a", marginRight: 10 }}
            />
            <h3>User Filter</h3>
          </>
        );
      case "BREAKPOINT":
        return (
          <>
            <CoffeeOutlined
              style={{ fontSize: 24, color: "#fd6109", marginRight: 10 }}
            />
            <h3>Pause & Wait</h3>
          </>
        );
      case "SURVEY_REVIEW":
        return (
          <>
            <AuditOutlined
              style={{ fontSize: 24, color: "#9bdb1c", marginRight: 10 }}
            />
            <h3>Survey Review</h3>
          </>
        );
      default:
        return (
          <>
            <UserOutlined
              style={{ fontSize: 24, color: "#0c71ff", marginRight: 10 }}
            />
            <h3>Default?</h3>
          </>
        );
    }
  };

  const renderAddNodeButton = (type: string) => {
    return (
      <UIAddNodeButton
        onClick={() => handleAddNode({ type, properties: {} } as FlowNode)}
      >
        {nodeTitle({ type } as FlowNode)}
      </UIAddNodeButton>
    );
  };

  const DragHandle = SortableHandle(() => (
    <UIDragHandle>
      <DragOutlined />
    </UIDragHandle>
  ));
  const SortableItem = SortableElement(({ value }: { value: FlowNode }) => {
    return (
      <UINodeContainer>
        <UINodeCard>
          <UINodeCardTitle>
            {nodeTitle(value)}
            <UINodeActions>
              <DragHandle />
              <UIDeleteButton onClick={() => handleDeleteNode(value)}>
                <DeleteOutlined />
              </UIDeleteButton>
            </UINodeActions>
          </UINodeCardTitle>
          <UINodeCardContent>
            <NodeInner
              node={value}
              attachPropertyToNode={attachPropertyToNode}
            />
          </UINodeCardContent>
        </UINodeCard>
        <UIArrowContainer>
          <UIConnectorLine />
        </UIArrowContainer>
      </UINodeContainer>
    );
  });

  const SortableList = SortableContainer(({ items }: { items: FlowNode[] }) => {
    return (
      <ul style={{ margin: 0, padding: 0 }}>
        {items.map((value: FlowNode, index: number) => (
          <SortableItem
            key={`item-${value.type}-${index}`}
            index={index}
            value={value}
          />
        ))}
      </ul>
    );
  });

  return (
    <UIContainer>
      <UINodeContainer>
        <UINodeCard>
          <UINodeCardTitle>
            {nodeTitle({ type: "USER" } as FlowNode)}
          </UINodeCardTitle>
          <UINodeCardContent>
            <p style={{ color: "#888" }}>
              Incoming user from Build Better
              {workflow?.webhookEnabled && " or webhook"}.
            </p>
          </UINodeCardContent>
        </UINodeCard>
        <UIArrowContainer>
          <UIConnectorLine />
        </UIArrowContainer>
      </UINodeContainer>
      {workflow && workflow.nodes && (
        <SortableList
          items={workflow.nodes}
          onSortEnd={onSortEnd}
          useDragHandle={true}
        />
      )}
      <UINodeContainer>
        <Popover
          placement="right"
          title="Add Step"
          content={
            <UIAddConatiner>
              {renderAddNodeButton("EMAIL")}
              {renderAddNodeButton("USER_FILTER")}
              {renderAddNodeButton("BREAKPOINT")}
              {renderAddNodeButton("SURVEY_REVIEW")}
            </UIAddConatiner>
          }
          trigger="click"
        >
          <PlusCircleOutlined style={{ fontSize: 24, color: "#888" }} />
        </Popover>
      </UINodeContainer>

      <UINodeContainer>
        <Card style={{ backgroundColor: "transparent", opacity: 0.5 }}>
          <UIFakeNode>End of Workflow</UIFakeNode>
        </Card>
      </UINodeContainer>
      <Divider />
    </UIContainer>
  );
};

const UIContainer = styled.div``;

const UINodeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const UINodeCard = styled.div`
  border-radius: 10px;
  border: #ccc 1px solid;
  background-color: #fff;
`;
const UINodeCardTitle = styled.div`
  border-bottom: #ccc 1px solid;
  padding: 20px 0 20px 20px;
  display: flex;
  align-items: center;
  height: 60px;
  h3 {
    margin: 0;
    height: 60px;
    line-height: 60px;
  }
`;
const UINodeCardContent = styled.div`
  padding: 20px;
  p {
    margin: 0;
  }
`;

const UINodeActions = styled.div`
  display: flex;
  margin-left: auto;
`;

const UIDragHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
  height: 59px;
  width: 50px;
  &:hover {
    background-color: #eee;
  }
`;
const UIDeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 59px;
  width: 50px;
  &:hover {
    background-color: #eee;
    border-top-right-radius: 10px;
  }
`;

const UIArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const UIConnectorLine = styled.div`
  width: 1px;
  height: 30px;
  background-color: #ccc;
`;

const UIAddConatiner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 410px;
`;

const UIAddNodeButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 200px;
  background-color: #fff;
  border: #eee 1px solid;
  margin: 5px 5px 0 0;
  svg {
    margin-bottom: 5px;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  }
`;

const UIFakeNode = styled.div``;

export default WorkflowEditorView;
